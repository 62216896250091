import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { AuthService } from "@core/services/auth/auth.service";
import {
	PaymentMethod,
	PaymentMethodInstallment,
	PaymentMethodSimulate,
	PaymentMethodTerms
} from "@data/schemas/payment-method";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable, tap } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class PaymentMethodService {
	private readonly PAYMENT_METHOD_ENDPOINT = "payment-methods";
	actualPaymentMethods = new Map<number, string>();
	actualPaymentMethodsInstallments = new Map<number, string>();

	constructor(
		private baseCrudBffService: BaseCrudBffService,
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	getAll(): Observable<PaymentMethod[]> {
		return this.baseCrudBffService
			.getAll<PaymentMethod[]>({
				path: this.PAYMENT_METHOD_ENDPOINT
			})
			.pipe(
				tap((result) => {
					result.forEach((paymentMethod) => {
						this.actualPaymentMethods.set(
							paymentMethod.id,
							paymentMethod.descricao
						);
					});
				})
			);
	}

	getAllPaymentMethodTerms(): Observable<PaymentMethodTerms[]> {
		return this.baseCrudBffService.getAll<PaymentMethodTerms[]>({
			path: `${this.PAYMENT_METHOD_ENDPOINT}/terms`
		});
	}

	getAllPaymentMethodInstallments(
		id: number,
		totalValue: number
	): Observable<PaymentMethodInstallment[]> {
		const params = new HttpParams()
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			)
			.set("totalValue", totalValue);
		return this.http.get<PaymentMethodInstallment[]>(
			`${this.config.environment.API_BFF}/${this.PAYMENT_METHOD_ENDPOINT}/installments/${id}`,
			{ params, withCredentials: true }
		);
	}

	getAllPaymentMethodInstallmentsOptionsFromAsaas(
		billingType: string,
		totalValue: number
	): Observable<PaymentMethodInstallment[]> {
		const params = new HttpParams()
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			)
			.set("billingType", billingType)
			.set("totalValue", totalValue);
		return this.http.get<PaymentMethodInstallment[]>(
			`${this.config.environment.API_BFF}/${this.PAYMENT_METHOD_ENDPOINT}/installments-options-asaas`,
			{ params, withCredentials: true }
		);
	}

	getSimulatePaymentFromAsaas(
		billingTypes: string,
		totalValue: number,
		installmentCount: string
	): Observable<PaymentMethodSimulate> {
		const params = new HttpParams()
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			)
			.set("billingTypes", billingTypes)
			.set("totalValue", totalValue)
			.set("installmentCount", installmentCount);
		return this.http.get<PaymentMethodSimulate>(
			`${this.config.environment.API_BFF}/${this.PAYMENT_METHOD_ENDPOINT}/simulate-payment-from-asaas`,
			{ params, withCredentials: true }
		);
	}
}
