import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { ReceiptDataFormGroup } from "../types/form";
import { AccountingAccount } from "@data/schemas/accounting-account";
import { CostCenter } from "@data/schemas/cost-center";
import { AccountingAccountService } from "@data/services/accounting-account.service";
import { CostCenterService } from "@data/services/cost-center.service";
import { ProjectService } from "@data/services/project.service";
import { Subject, takeUntil } from "rxjs";
import { DataList } from "@data/schemas/filter/data-list";
import { Project } from "@data/schemas/project";
import { Steps } from "../types/steps.enum";
import { AccountingAccountVersion } from "@data/schemas/accounting-account/accounting-account-version";

@Component({
	selector: "app-management-organization-details",
	templateUrl: "./management-organization-details.component.html",
	styleUrl: "./management-organization-details.component.scss",
	standalone: false
})
export class ManagementOrganizationDetailsComponent
	implements OnInit, OnChanges, OnDestroy
{
	private _onDestroy = new Subject<boolean>();

	@Input()
	public formGroupName!: string;

	@Input()
	public receiptForm!: FormGroup;

	@Input()
	currentStep!: string;

	private costCenterMap: { [id: number]: CostCenter } = {};
	private projectMap: { [id: number]: Project } = {};

	protected form!: FormGroup<ReceiptDataFormGroup>;
	protected costCenters: CostCenter[] = [];
	protected accountingAccounts: AccountingAccount[] = [];
	protected projects: Project[] = [];
	protected defaultAccountingAccount: AccountingAccount | null = null;
	protected defaultClassificationAccountingOld = "1.1";
	private isNewAccountingAccount = false;

	readonly EMPTY_STRING = "";
	readonly SEPARATING_HYPHEN = " - ";
	readonly FIND_ACCOUNTING_ACCOUNT_TYPES = "0,12,5";

	constructor(
		private controlContainer: ControlContainer,
		private accountingAccountService: AccountingAccountService,
		private costCenterService: CostCenterService,
		private projectService: ProjectService
	) {}

	ngOnInit(): void {
		this.form = this.controlContainer
			.control as FormGroup<ReceiptDataFormGroup>;
		this.verifyIfIsNewUser();
	}

	ngOnChanges(): void {
		if (this.currentStep === Steps.RECEIPT_DATA.toString()) {
			this.getCostCenters();
			this.getAccountingAccounts();
			this.getProjects();
		}
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this._onDestroy.complete();
	}

	private getAccountingAccounts(): void {
		this.accountingAccountService
			.findAll("", this.FIND_ACCOUNTING_ACCOUNT_TYPES)
			.pipe(takeUntil(this._onDestroy))
			.subscribe((accountingAccounts: DataList<AccountingAccount>) => {
				this.accountingAccounts =
					this.accountingAccountService.sortManualAccountingAccountsClassification(
						this.accountingAccountService.FilteredManualAccountingAccounts(
							accountingAccounts.content
						)
					);

				if (!this.isNewAccountingAccount) {
					this.defaultAccountingAccount =
						this.accountingAccounts.filter(
							(account) =>
								account?.codigo ===
								this.defaultClassificationAccountingOld
						)?.[0] ?? null;
					return;
				}

				this.defaultAccountingAccount =
					this.accountingAccounts?.[0] ?? null;
			});
	}

	protected getSelectedAccountingAccount(): void {
		const selectedAccountingAccountId = Number(
			this.form.controls.accountingAccount.value
		);
		const selectedAccountingAccount = this.accountingAccounts.find(
			(accountingAccount) =>
				accountingAccount.id === selectedAccountingAccountId
		);

		if (selectedAccountingAccount) {
			this.form.controls.accountingAccountName.patchValue(
				selectedAccountingAccount.nomeConta
			);
		}
	}

	private getCostCenters(): void {
		this.costCenterService
			.findAll()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((costCenters: DataList<CostCenter>) => {
				this.costCenters = costCenters.content;
				costCenters.content.forEach((costCenter) => {
					this.costCenterMap[Number(costCenter.id)] = costCenter;
				});
			});
	}

	protected getSelectedCostCenter(): void {
		const selectedCostCenterId = Number(
			this.form.controls.costCenter.value
		);
		const selectedCostCenter = this.costCenterMap[selectedCostCenterId];

		if (selectedCostCenter) {
			this.form.controls.costCenterName.patchValue(
				selectedCostCenter.descricao
			);
		}
	}

	private getProjects(): void {
		this.projectService
			.getAllProject()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((projects: DataList<Project>) => {
				this.projects = projects.content;
				projects.content.forEach((project) => {
					this.projectMap[Number(project.id)] = project;
				});
			});
	}

	protected getSelectedProject(): void {
		const selectedProjectId = Number(this.form.controls.project.value);
		const selectedProject = this.projectMap[selectedProjectId];

		if (selectedProject) {
			this.form.controls.projectName.patchValue(
				selectedProject.descricao
			);
		}
	}

	protected getCodeAndSeparatingHyphen(code: string | undefined): string {
		if (!code) {
			return this.EMPTY_STRING;
		}

		return code + this.SEPARATING_HYPHEN;
	}

	verifyIfIsNewUser(): void {
		this.accountingAccountService
			.getAccountingAccountVersion()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((version: AccountingAccountVersion) => {
				this.isNewAccountingAccount =
					version.newAccountingAccountsEnabled;
			});
	}
}
