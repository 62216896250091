import {
	Component,
	Inject,
	Input,
	LOCALE_ID,
	OnChanges,
	OnDestroy,
	OnInit,
	computed,
	signal
} from "@angular/core";
import {
	FormArray,
	FormControl,
	FormGroup,
	FormGroupDirective
} from "@angular/forms";
import {
	PaymentMethodInstallment,
	PaymentMethodSimulate
} from "@data/schemas/payment-method";
import { BusinessService } from "@data/services/business.service";
import { PaymentMethodService } from "@data/services/payment-method.service";
import { CheckboxDetailsOptions } from "@shared/components/atlas/checkbox-details/types";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { DecimalTransformer } from "@shared/utils/forms/decimal-transformer";
import {
	Subject,
	debounceTime,
	distinctUntilChanged,
	switchMap,
	takeUntil
} from "rxjs";
import { ReceiptDataFormGroup } from "../types/form";
import { Steps } from "../types/steps.enum";
import { PersistenceUnitService } from "@data/services/persistence-unit.service";

@Component({
	selector: "app-asaas-receipt-data-step",
	templateUrl: "./asaas-receipt-data-step.component.html",
	styleUrl: "./asaas-receipt-data-step.component.scss",
	standalone: false
})
export class AsaasReceiptDataStepComponent
	implements OnInit, OnChanges, OnDestroy
{
	protected form!: FormGroup<ReceiptDataFormGroup>;
	protected billingTypeEnumForm!: FormArray;
	protected receiptDueDateLabel = "";
	protected optionsCheckboxDetails = signal<CheckboxDetailsOptions[]>([]);
	protected maxValue = this.maxReceiptValue();
	protected maxValueWithLinkedItem = signal(0);
	protected maxValueErrorInLinkedOption = signal("");
	protected isMigratedAccountingAccounts = signal(false);

	protected defaultInstallmentsOptionsAsaas =
		signal<PaymentMethodInstallment | null>(null);

	protected paymethodMethodsInstallmentsOptionsAsaas = signal<
		PaymentMethodInstallment[]
	>([]);

	protected paymentMethodInstallmentLabel = computed(() => {
		const labels: string[] = [];
		this.paymethodMethodsInstallmentsOptionsAsaas().forEach((item) => {
			labels.push(this.getPaymentMethodInstallmentLabel(item));
		});

		return labels;
	});

	private _onDestroy = new Subject<boolean>();
	private billingTypesString = "UNDEFINED";
	private billingTypesAsaas = "BOLETO,CREDIT_CARD,PIX";
	private installments = "PARCELADO";
	private single = "UNICO";
	private readonly INSTALLMENT_COUNT_ONE = 1;
	private readonly INSTALLMENT_COUNT_TWELVE = 12;
	private readonly EMPTY_STRING = "";
	private readonly BRL_ZERO_STRING = "R$ ";
	private readonly BRL_ZERO_REGEX = /R\$ 0,00/g;
	private readonly PERCENTAGE_ZERO_REGEX = /0,00%/g;

	private paymentMethodSimulate = signal<PaymentMethodSimulate | undefined>(
		undefined
	);

	private maxValueErrorDefaultMessage =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.RECEIPT_DATA_STEP.MESSAGE.MAX_VALUE"
		);

	@Input()
	public formGroupName!: string;

	@Input()
	public receiptForm!: FormGroup;

	@Input() bankTypeForm: FormGroup | null = null;

	@Input() currentStep: string = "";

	@Input() interactionId: string = "";

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private automataTranslateService: AutomataTranslateService,
		private paymentMethodService: PaymentMethodService,
		private rootFormGroup: FormGroupDirective,
		private businessService: BusinessService,
		private persistenceUnitService: PersistenceUnitService
	) {}

	ngOnInit(): void {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<ReceiptDataFormGroup>;

		this.optionsCheckboxDetails.set(
			this.createEmptyOptionsCheckboxDetails()
		);

		this.billingTypeEnumForm = this.form.controls.billingTypeEnum;

		this.verifyIfIsMigratedAccountingAccounts();
		this.handleCheckboxes();
		this.updateReceiptDueDateLabel();
		this.updateInstallmentOptionsWithTotalValueFinalAsaas();
		this.onChangeAtlasSelectionCard();
		this.onChangeInstallmentValueSimulatePaymentAsaas();
	}

	ngOnChanges(): void {
		if (this.currentStep === Steps.RECEIPT_DATA.toString()) {
			this.updateMaxValue();
		}
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this._onDestroy.complete();
	}

	protected onChangeSelectInstallments() {
		if (this.form.controls.numberInstallments.value) {
			if (
				Number(this.form.controls.numberInstallments.value) ===
				this.INSTALLMENT_COUNT_ONE
			) {
				this.receiptDueDateLabel =
					this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_DUE_DATE"
					);
			}

			if (
				Number(this.form.controls.numberInstallments.value) >
				this.INSTALLMENT_COUNT_ONE
			) {
				this.receiptDueDateLabel =
					this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_FIRST_INSTALLMENT_DUE_DATE"
					);
			}
		}
	}

	private updateInstallmentOptionsWithTotalValueFinalAsaas(): void {
		if (DecimalTransformer(this.form.controls.totalValue.value) > 0) {
			this.updateInstallmentOptions();
		}

		this.form.controls.totalValue.valueChanges
			.pipe(
				debounceTime(500),
				takeUntil(this._onDestroy),
				switchMap((value) => {
					if (Number(value) === 0) {
						this.resetSimulationFormValues();
					}
					return this.paymentMethodService.getAllPaymentMethodInstallmentsOptionsFromAsaas(
						this.billingTypesString,
						DecimalTransformer(value) || 0
					);
				})
			)
			.subscribe((result) => {
				this.handleInstallmentOptionsResult(result);
			});
	}

	private getPaymentMethodInstallmentLabel(
		installment: PaymentMethodInstallment
	): string {
		if (
			this.isInstallmentCountEqualsOneAndValueGreaterThanZero(installment)
		) {
			return `${installment.description}(${installment.value})`;
		}

		if (
			this.isInstallmentCountGreaterThanOneAndValueGreaterThanZero(
				installment
			)
		) {
			return `${installment.description}${installment.value}`;
		}

		return this.EMPTY_STRING;
	}

	private onChangeAtlasSelectionCard(): void {
		this.billingTypeEnumForm.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.getBillingTypeEnumValues();
				this.updatePaymentMethodInstallmentsOptionsFromAsaas(
					this.billingTypesString
				);

				if (
					this.isCreditCard() &&
					this.getNumberInstallments() > this.INSTALLMENT_COUNT_TWELVE
				) {
					this.form
						?.get("numberInstallments")
						?.patchValue(this.INSTALLMENT_COUNT_TWELVE);
				}
			});
	}

	private numberFormatter(value: number): string {
		return Intl.NumberFormat(this.locale, {
			minimumFractionDigits: 2
		}).format(value);
	}

	private maxReceiptValue(): number {
		if (this.isFisicalPerson()) {
			return 5000;
		}
		return 150000;
	}

	private isFisicalPerson(): boolean {
		const businessDefaultData = this.businessService.getBusinessData();
		return businessDefaultData?.tipoPessoa.id === "FISICA";
	}

	private updateMaxValue(): void {
		const linkedItemValue = this.bankTypeForm?.controls.linkedItemValue
			.value as number;
		if (linkedItemValue) {
			this.maxValueWithLinkedItem.set(linkedItemValue);
			this.updateMaxValueErrorMessage();
			return;
		}
		this.updateMaxValueErrorMessage();
	}

	private updateMaxValueErrorMessage(): void {
		this.maxValueErrorInLinkedOption.set(
			this.maxValueErrorDefaultMessage.replace(
				"{value}",
				this.bankTypeForm?.controls.linkedToggleSelected.value
					? this.numberFormatter(
							this.bankTypeForm?.controls.linkedItemValue
								.value as number
						)
					: this.numberFormatter(this.maxValue)
			)
		);
	}

	private updateInstallmentOptions(): void {
		this.paymentMethodService
			.getAllPaymentMethodInstallmentsOptionsFromAsaas(
				this.billingTypesString,
				DecimalTransformer(this.form.controls.totalValue.value) || 0
			)
			.subscribe((result) => {
				this.handleInstallmentOptionsResult(result);
			});
	}

	private handleInstallmentOptionsResult(
		result: PaymentMethodInstallment[]
	): void {
		if (result.length > 0) {
			result.forEach((installment) => {
				if (
					Number(installment.installmentCount) ===
					this.INSTALLMENT_COUNT_ONE
				) {
					this.defaultInstallmentsOptionsAsaas.set(installment);
				}
			});
		}

		this.paymethodMethodsInstallmentsOptionsAsaas.set([...result]);
		this.simulatePaymentFromAsaas();
	}

	private isInstallmentCountEqualsOneAndValueGreaterThanZero(
		installment: PaymentMethodInstallment
	): boolean {
		return (
			installment.installmentCount === this.INSTALLMENT_COUNT_ONE &&
			Number(installment.valueFromAsaas) > 0
		);
	}

	private isInstallmentCountGreaterThanOneAndValueGreaterThanZero(
		installment: PaymentMethodInstallment
	): boolean {
		return (
			installment.installmentCount > this.INSTALLMENT_COUNT_ONE &&
			Number(installment.valueFromAsaas) > 0
		);
	}

	private updateReceiptDueDateLabel(): void {
		this.receiptDueDateLabel =
			this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_DUE_DATE"
			);
	}

	private updateValueToReceiptFromAsaas(installmentCount: number): void {
		const options = this.paymethodMethodsInstallmentsOptionsAsaas();

		if (options && options[installmentCount - 1]) {
			const valueFromAsaas =
				this.paymethodMethodsInstallmentsOptionsAsaas()[
					installmentCount - 1
				].valueFromAsaas;

			this.form.controls.valueToReceive.patchValue(valueFromAsaas);
		}
	}

	private onChangeInstallmentValueSimulatePaymentAsaas() {
		this.form.controls.numberInstallments.valueChanges
			.pipe(distinctUntilChanged(), takeUntil(this._onDestroy))
			.subscribe((installmentCount) => {
				if (installmentCount) {
					this.form.controls.duplicateType.patchValue(
						installmentCount > this.INSTALLMENT_COUNT_ONE
							? this.installments
							: this.single
					);

					this.updateValueToReceiptFromAsaas(installmentCount);
				}

				this.updatePaymentMethodInstallmentsOptionsFromAsaas(
					this.billingTypesString
				);
			});
	}

	private updatePaymentMethodInstallmentsOptionsFromAsaas(billingType = "") {
		this.getBillingTypeEnumValues();
		if (this.getSelectedBillingType(billingType)) {
			this.paymentMethodService
				.getAllPaymentMethodInstallmentsOptionsFromAsaas(
					this.getSelectedBillingType(billingType),
					DecimalTransformer(this.form.controls.totalValue?.value) ||
						0
				)
				.subscribe((result) => {
					if (result.length > 0) {
						result.forEach((installment) => {
							if (
								Number(installment.installmentCount) ===
								this.INSTALLMENT_COUNT_ONE
							) {
								this.defaultInstallmentsOptionsAsaas.set(
									installment
								);
							}
						});
					}

					this.paymethodMethodsInstallmentsOptionsAsaas.set([
						...result
					]);
					this.simulatePaymentFromAsaas();
				});
		}
	}

	private getSelectedBillingType(billingType: string): string {
		return billingType !== this.billingTypesString
			? billingType
			: this.billingTypesString;
	}

	private simulatePaymentFromAsaas(): void {
		if (
			this.form.controls.totalValue?.value &&
			this.getNumberInstallments() > 0
		) {
			this.paymentMethodService
				.getSimulatePaymentFromAsaas(
					this.getBillingTypeForFilter(),
					DecimalTransformer(this.form.controls.totalValue?.value),
					this.getNumberInstallmentsForFilter()
				)
				.subscribe((result) => {
					this.paymentMethodSimulate.set(result);
					this.updateSubtitleWithValueSimulateAsaas();
					this.updateDescriptionWithValueSimulateAsaas();
					this.updateValueToReceiptFromAsaas(
						Number(this.form.controls.numberInstallments.value)
					);
				});
		}
	}

	private getNumberInstallmentsForFilter(): string {
		const lastPaymethodMethodsInstallments =
			this.paymethodMethodsInstallmentsOptionsAsaas()[
				this.paymethodMethodsInstallmentsOptionsAsaas().length - 1
			];

		if (
			this.getNumberInstallments() >
			lastPaymethodMethodsInstallments.installmentCount
		) {
			this.form
				?.get("numberInstallments")
				?.patchValue(lastPaymethodMethodsInstallments.installmentCount);
			return String(lastPaymethodMethodsInstallments.installmentCount);
		}

		return String(this.getNumberInstallments());
	}

	private getBillingTypeForFilter(): string {
		if (Number(this.getNumberInstallmentsForFilter()) <= 12) {
			return "BOLETO,CREDIT_CARD,PIX,UNDEFINED";
		}

		if (this.isBankSlip() && this.isCreditCard()) {
			return this.getNumberInstallments() > this.INSTALLMENT_COUNT_TWELVE
				? "BOLETO,CREDIT_CARD"
				: this.billingTypesAsaas;
		}

		if (this.isBankSlip()) {
			return this.getNumberInstallments() > this.INSTALLMENT_COUNT_TWELVE
				? "BOLETO"
				: "BOLETO,PIX";
		}

		if (this.isCreditCard()) {
			return "CREDIT_CARD";
		}

		return this.billingTypesAsaas;
	}

	private getNumberInstallments(): number {
		return Number(this.form.controls.numberInstallments?.value);
	}

	private handleCheckboxes(): void {
		while (this.billingTypeEnumForm.length) {
			this.billingTypeEnumForm.removeAt(0);
		}

		this.optionsCheckboxDetails().forEach(() =>
			this.billingTypeEnumForm.push(new FormControl(true))
		);
	}

	private getBillingTypeEnumValue(): boolean[] {
		return this.billingTypeEnumForm.value as boolean[];
	}

	private isBankSlip(): boolean {
		return this.getBillingTypeEnumValue()[0];
	}

	private isCreditCard(): boolean {
		return this.getBillingTypeEnumValue()[1];
	}

	private getBillingTypeEnumValues(): void {
		this.billingTypesString = this.getBillingTypeOption(
			this.isBankSlip(),
			this.isCreditCard()
		);
	}

	private getBillingTypeOption(
		isBoleto: boolean,
		isCreditCard: boolean
	): string {
		if (isBoleto && isCreditCard) return "UNDEFINED";
		if (isBoleto) return "BOLETO";
		if (isCreditCard) return "CREDIT_CARD";
		return this.EMPTY_STRING;
	}

	private createEmptyOptionsCheckboxDetails(): CheckboxDetailsOptions[] {
		return [
			{
				title: this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_SLIP_PIX"
				),
				subtitle: this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_SLIP_PIX_SUBTITLE"
				),
				value: "BOLETO,PIX",
				description: [
					this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_SLIP_DESCRIPTION"
					),
					this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.RECEIPT_DATA_STEP.PIX_DESCRIPTION"
					)
				]
			},
			{
				title: this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.RECEIPT_DATA_STEP.CREDIT_CARD"
				),
				subtitle: this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_SLIP_PIX_SUBTITLE"
				),
				value: "CREDIT_CARD",
				description: [
					this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.RECEIPT_DATA_STEP.CREDIT_CARD_TAX_DESCRIPTION"
					),
					this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.RECEIPT_DATA_STEP.CREDIT_CARD_RECEIPT_AT"
					)
				]
			}
		];
	}

	private updateDescriptionWithValueSimulateAsaas(): void {
		if (!this.paymentMethodSimulate()) {
			return;
		}

		const data = this.optionsCheckboxDetails();

		data[0].description[0] = data[0].description[0].replace(
			this.BRL_ZERO_REGEX,
			this.BRL_ZERO_STRING +
				this.numberFormatter(
					this.paymentMethodSimulate()?.bankSlipFeeValue ?? 0
				)
		);

		data[0].description[1] = data[0].description[1].replace(
			this.BRL_ZERO_REGEX,
			this.BRL_ZERO_STRING +
				this.numberFormatter(
					this.paymentMethodSimulate()?.bankSlipFeeValue ?? 0
				)
		);

		data[1].description[0] = data[1].description[0].replace(
			this.PERCENTAGE_ZERO_REGEX,
			this.numberFormatter(
				this.paymentMethodSimulate()?.creditCardFeePercentage ?? 0
			) + "%"
		);

		data[1].description[0] = data[1].description[0].replace(
			this.BRL_ZERO_REGEX,
			this.BRL_ZERO_STRING +
				this.numberFormatter(
					this.paymentMethodSimulate()?.creditCardOperationFee ?? 0
				)
		);

		data[1].description[1] = data[1].description[1].replace(
			this.BRL_ZERO_REGEX,
			this.BRL_ZERO_STRING +
				this.numberFormatter(
					this.paymentMethodSimulate()?.creditCardOperationFee ?? 0
				)
		);

		this.form.controls.bankSlipPopoverContent?.patchValue(
			`${this.optionsCheckboxDetails()[0].description[0]}<br>
        ${this.optionsCheckboxDetails()[0].description[1]}`
		);

		this.form.controls.creditCardPopoverContent?.patchValue(
			`${this.optionsCheckboxDetails()[1].description[0]}<br>
      ${this.optionsCheckboxDetails()[1].description[1]}`
		);

		this.optionsCheckboxDetails.set([...data]);
	}

	private updateSubtitleWithValueSimulateAsaas(): void {
		if (!this.paymentMethodSimulate()) {
			return;
		}

		const installmentCount = DecimalTransformer(
			this.form.controls.numberInstallments.value
		);

		const isInstallmentPaymentMethod =
			!!this.paymentMethodSimulate() && installmentCount > 1;

		const bankSlipPixNetValue = isInstallmentPaymentMethod
			? this.paymentMethodSimulate()?.bankSlipInstallmentPaymentNetValue
			: this.paymentMethodSimulate()?.bankSlipNetValue;

		const creditCardNetValue = isInstallmentPaymentMethod
			? this.paymentMethodSimulate()?.creditCardPaymentNetValue
			: this.paymentMethodSimulate()?.creditCardNetValue;

		this.form.controls.bankSlipPixNetValue?.patchValue(
			bankSlipPixNetValue ?? 0
		);
		this.form.controls.creditCardNetValue?.patchValue(
			creditCardNetValue ?? 0
		);

		const titleNetValue = isInstallmentPaymentMethod
			? this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.RECEIPT_DATA_STEP.CREDIT_CARD_SUBTITLE"
				)
			: this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_SLIP_PIX_SUBTITLE"
				);

		const optionsCheckboxDetailsData = this.optionsCheckboxDetails();

		optionsCheckboxDetailsData[0].subtitle = titleNetValue.replace(
			this.BRL_ZERO_REGEX,
			this.BRL_ZERO_STRING +
				this.numberFormatter(bankSlipPixNetValue ?? 0)
		);

		optionsCheckboxDetailsData[1].subtitle = titleNetValue.replace(
			this.BRL_ZERO_REGEX,
			this.BRL_ZERO_STRING + this.numberFormatter(creditCardNetValue ?? 0)
		);

		this.optionsCheckboxDetails.set([...optionsCheckboxDetailsData]);
	}

	private resetSimulationFormValues(): void {
		const emptyPaymentMethodSimulate = {} as PaymentMethodSimulate;
		Object.keys(emptyPaymentMethodSimulate).forEach((key) => {
			emptyPaymentMethodSimulate[key as keyof PaymentMethodSimulate] = 0;
		});
		this.paymentMethodSimulate.set(emptyPaymentMethodSimulate);

		this.form.controls.numberInstallments.patchValue(
			this.INSTALLMENT_COUNT_ONE
		);

		this.updateSubtitleWithValueSimulateAsaas();

		this.optionsCheckboxDetails.set(
			this.createEmptyOptionsCheckboxDetails()
		);
		this.updateDescriptionWithValueSimulateAsaas();
	}

	private verifyIfIsMigratedAccountingAccounts(): void {
		this.persistenceUnitService
			.getMigratedAccountingAccounts()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((data) => {
				this.isMigratedAccountingAccounts.set(
					data.migratedAccountingAccounts
				);
			});
	}
}
