<atlas-form [formGroup]="receiptForm" data-testid="wizard-new-receipt">
	@if (loading) {
		<div class="atlas-loading-backdrop">
			<atlas-loading
				[message]="'CREATE_RECEIPT.LABEL.LOADING_MESSAGE' | translate"
			></atlas-loading>
		</div>
	}

	<atlas-wizard
		[header]="'CREATE_RECEIPT.TITLE' | translate"
		(atlas-wizard-close)="closeWizard()"
		(atlas-wizard-finish)="submitWizard($event)"
		(atlas-wizard-change-step)="setStep($event)"
	>
		<atlas-wizard-step
			[name]="steps.BANK_AND_RECEIPT_TYPE_DATA"
			[header]="
				'CREATE_RECEIPT.LABEL.BANK_AND_RECEIPT_TYPE_DATA_HEADER'
					| translate
			"
			[active]="true"
		>
			<app-bank-and-receipt-type-data
				[receiptForm]="receiptForm"
				[formGroupName]="steps.BANK_AND_RECEIPT_TYPE_DATA"
				[currentStep]="currentStep"
				[interactionId]="interactionId"
			></app-bank-and-receipt-type-data
		></atlas-wizard-step>

		<atlas-wizard-step
			[name]="steps.RECEIPT_DATA"
			[header]="'CREATE_RECEIPT.LABEL.RECEIPT_DATA_HEADER' | translate"
			[active]="true"
		>
			@if (isBankAsaas()) {
				<app-asaas-receipt-data-step
					[bankTypeForm]="receiptForm.controls.bankAndReceiptTypeData"
					[formGroupName]="steps.RECEIPT_DATA"
					[currentStep]="currentStep"
					[interactionId]="interactionId"
				></app-asaas-receipt-data-step>
			} @else {
				<app-other-banks-receipt-data-step
					[bankTypeForm]="receiptForm.controls.bankAndReceiptTypeData"
					[formGroupName]="steps.RECEIPT_DATA"
					[currentStep]="currentStep"
					[interactionId]="interactionId"
				></app-other-banks-receipt-data-step>
			}
		</atlas-wizard-step>
		<atlas-wizard-step
			[name]="steps.CUSTOMER_DATA"
			[header]="'CREATE_RECEIPT.LABEL.CUSTOMER_DATA_HEADER' | translate"
		>
			<app-customer-data-step
				[bankTypeForm]="receiptForm.controls.bankAndReceiptTypeData"
				[currentStep]="currentStep"
				[formGroupName]="steps.CUSTOMER_DATA"
				[interactionId]="interactionId"
			></app-customer-data-step>
		</atlas-wizard-step>
		<atlas-wizard-step
			[name]="steps.SUMMARY"
			[header]="'CREATE_RECEIPT.LABEL.SUMMARY_HEADER' | translate"
		>
			@if (currentStep === steps.SUMMARY) {
				<app-summary-data-step
					[receiptForm]="receiptForm"
					[interactionId]="interactionId"
				></app-summary-data-step>
			}
		</atlas-wizard-step>
	</atlas-wizard>
</atlas-form>
