import {
	Component,
	ElementRef,
	Inject,
	Input,
	LOCALE_ID,
	OnChanges,
	OnDestroy,
	OnInit,
	ViewChild,
	signal
} from "@angular/core";
import { EMPTY, Subject, catchError, debounceTime, takeUntil } from "rxjs";
import { ReceiptDataFormGroup } from "../types/form";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { IAtlasSelect } from "@shared/types/atlas";
import { PaymentMethodService } from "@data/services/payment-method.service";
import {
	PaymentMethod,
	PaymentMethodInstallment
} from "@data/schemas/payment-method";
import { AtlasSelectProperties } from "@shared/components/atlas/types/atlas-select-properties";
import { DecimalTransformer } from "@shared/utils/forms/decimal-transformer";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { DateTime } from "luxon";
import { DefaultPaymentMethodNamesEnum } from "@enums/default-payment-method-names.enum";
import { Steps } from "../types/steps.enum";
import { PersistenceUnitService } from "@data/services/persistence-unit.service";

@Component({
	selector: "app-other-banks-receipt-data-step",
	templateUrl: "./other-banks-receipt-data-step.component.html",
	styleUrl: "./other-banks-receipt-data-step.component.scss",
	standalone: false
})
export class OtherBanksReceiptDataStepComponent
	implements OnInit, OnChanges, OnDestroy
{
	private _onDestroy = new Subject<boolean>();
	private selectedPaymentMethod: number | undefined;

	protected form!: FormGroup<ReceiptDataFormGroup>;
	protected installments = "PARCELADO";
	protected single = "UNICO";
	protected receiptDueDateLabel = "";
	protected inCash = "À vista";
	protected defaultMethodInstallment =
		signal<PaymentMethodInstallment | null>(null);
	protected paymentMethods = signal<PaymentMethod[]>([]);
	protected paymentMethodsInstallments = signal<PaymentMethodInstallment[]>(
		[]
	);
	protected maxValueWithLinkedItem = signal(0);
	protected maxValueErrorDefaultMessage =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.RECEIPT_DATA_STEP.MESSAGE.MAX_VALUE"
		);
	protected maxValueErrorInLinkedOption = signal("");

	@Input()
	public formGroupName!: string;

	@Input() bankTypeForm: FormGroup | null = null;

	@Input() currentStep: string = "";

	@Input() interactionId: string = "";

	@ViewChild("atlasSelectPaymentMethod")
	protected atlasSelectPaymentMethod!: ElementRef<IAtlasSelect>;

	@ViewChild("atlasSelectPaymentMethodInstallment")
	protected atlasSelectPaymentMethodInstallment!: ElementRef<IAtlasSelect>;

	readonly INSTALLMENT_COUNT_ONE = 1;

	protected isMigratedAccountingAccounts = signal(false);

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private rootFormGroup: FormGroupDirective,
		private paymentMethodService: PaymentMethodService,
		private automataTranslateService: AutomataTranslateService,
		private persistenceUnitService: PersistenceUnitService
	) {}

	ngOnInit(): void {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<ReceiptDataFormGroup>;

		this.verifyIfIsMigratedAccountingAccounts();
		this.changeInstallmentSelectWhenTotalValueChanges();
		this.setReceiptDueDateLabel(true);
		this.cleanPaymentMethodWhenBanksChange();
	}

	ngOnChanges(): void {
		if (this.currentStep === Steps.RECEIPT_DATA.toString()) {
			this.updateMaxValue();
			this.getPaymentMethods();
		}
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this._onDestroy.complete();
	}

	private getPaymentMethods(): void {
		this.paymentMethodService
			.getAll()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((paymentMethods: PaymentMethod[]) => {
				this.paymentMethods.set(paymentMethods);
				paymentMethods.sort((a, b) =>
					a.descricao.localeCompare(b.descricao)
				);
			});
	}

	protected getSelectedPaymentMethod(): void {
		const selectedOption: AtlasSelectProperties =
			this.atlasSelectPaymentMethod.nativeElement.getSelectedOptions();

		if (selectedOption && selectedOption.label) {
			this.form.controls.paymentMethodName.patchValue(
				selectedOption.label
			);

			this.selectedPaymentMethod = selectedOption.value;

			this.getAllPaymentMethodInstallments(
				selectedOption.value,
				DecimalTransformer(this.form.controls.totalValue?.value)
			);
		}
	}

	protected getSelectedPaymentMethodInstallment(): void {
		const selectedOption: AtlasSelectProperties =
			this.atlasSelectPaymentMethodInstallment.nativeElement.getSelectedOptions();

		if (!selectedOption) {
			return;
		}

		if (selectedOption.value) {
			const selectedInstallment = this.getInstallmentById(
				selectedOption.value
			);
			this.setNumberInstallmentsAndValueToReceiveOnForm(
				selectedInstallment!
			);
			this.setBillingType(selectedInstallment!);
			this.setDefaultValuesToFirstInstallment(selectedInstallment!);
		}

		if (selectedOption.label) {
			const includesInCash = selectedOption.label.includes(this.inCash);
			this.setReceiptDueDateLabel(includesInCash);

			this.form.controls.paymentMethodTermName.patchValue(
				selectedOption.label
			);
		}
	}

	protected setLabelToPaymentMethodTerms(
		paymentMethodInstallment: PaymentMethodInstallment
	): string {
		const validPaymentMethods = [
			DefaultPaymentMethodNamesEnum.bankSlipOrPix.toString(),
			DefaultPaymentMethodNamesEnum.creditCard.toString(),
			DefaultPaymentMethodNamesEnum.money.toString()
		];
		const paymentMethodName =
			this.form.controls.paymentMethodName.value ?? "";

		if (validPaymentMethods.includes(paymentMethodName)) {
			return paymentMethodInstallment.installmentCount ===
				this.INSTALLMENT_COUNT_ONE
				? paymentMethodInstallment.description +
						"(" +
						paymentMethodInstallment.value +
						")"
				: paymentMethodInstallment.description +
						paymentMethodInstallment.value;
		}

		return (
			paymentMethodInstallment.customDescription +
			" (" +
			paymentMethodInstallment.installmentCount +
			"x de " +
			paymentMethodInstallment.value +
			")"
		);
	}

	private getAllPaymentMethodInstallments(id: number, value: number) {
		this.paymentMethodService
			.getAllPaymentMethodInstallments(id, value)
			.pipe(catchError(() => EMPTY))
			.subscribe((installments: PaymentMethodInstallment[]) => {
				if (installments.length > 0) {
					const [firstElement] = installments;
					this.setDefaultValuesToFirstInstallment(firstElement);
				}

				this.paymentMethodsInstallments.set([...installments]);
			});
	}

	private setDefaultValuesToFirstInstallment(
		installment: PaymentMethodInstallment
	): void {
		this.defaultMethodInstallment.set(installment);
		this.form.controls.dueDate.patchValue(
			this.getDueDateDefaultValue(installment.days)
		);
		this.form.controls.valueToReceive.patchValue(
			installment?.valueFromAsaas
		);
		this.form.controls.numberInstallments.patchValue(
			installment?.installmentCount
		);
		this.setBillingType(installment);
	}

	private getDueDateDefaultValue(days: number): string {
		return DateTime.now().plus({ days: days }).toFormat("dd/MM/yyyy");
	}

	private setNumberInstallmentsAndValueToReceiveOnForm(
		selectedInstallment: PaymentMethodInstallment
	): void {
		if (!selectedInstallment) {
			return;
		}
		this.form.controls.numberInstallments.patchValue(
			selectedInstallment?.installmentCount
		);
		this.form.controls.valueToReceive.patchValue(
			selectedInstallment?.valueFromAsaas
		);
	}

	private getInstallmentById(
		idInstallment: number
	): PaymentMethodInstallment | undefined {
		return this.paymentMethodsInstallments().find(
			(installment) => installment.idInstallment === idInstallment
		);
	}

	private setReceiptDueDateLabel(inCash: boolean): void {
		if (inCash) {
			this.receiptDueDateLabel =
				this.automataTranslateService.getTranslationSync(
					"CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.RECEIPT_DUE_DATE"
				);
			return;
		}

		this.receiptDueDateLabel =
			this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.RECEIPT_FIRST_INSTALLMENT_DUE_DATE"
			);
	}

	private setBillingType(
		selectedInstallment: PaymentMethodInstallment
	): void {
		this.form.controls.duplicateType.patchValue(
			selectedInstallment.installmentCount > this.INSTALLMENT_COUNT_ONE
				? this.installments
				: this.single
		);
	}

	private changeInstallmentSelectWhenTotalValueChanges(): void {
		this.form.controls.totalValue.valueChanges
			.pipe(debounceTime(500), takeUntil(this._onDestroy))
			.subscribe((value) => {
				if (this.selectedPaymentMethod) {
					this.getAllPaymentMethodInstallments(
						this.selectedPaymentMethod,
						DecimalTransformer(value)
					);
				}
			});
	}

	private updateMaxValue(): void {
		const linkedItemValue = this.bankTypeForm?.controls.linkedItemValue
			.value as number;
		if (linkedItemValue) {
			this.maxValueWithLinkedItem.set(linkedItemValue);
			this.updateMaxValueErrorMessage();
			return;
		}
		this.updateMaxValueErrorMessage();
	}

	private updateMaxValueErrorMessage(): void {
		this.maxValueErrorInLinkedOption.set(
			this.maxValueErrorDefaultMessage.replace(
				"{value}",
				this.bankTypeForm?.controls.linkedToggleSelected.value
					? this.numberFormatter(
							this.bankTypeForm?.controls.linkedItemValue
								.value as number
						)
					: this.numberFormatter(99999999999999)
			)
		);
	}

	private numberFormatter(value: number): string {
		return Intl.NumberFormat(this.locale, {
			minimumFractionDigits: 2
		}).format(value);
	}

	private cleanPaymentMethodWhenBanksChange(): void {
		this.form.controls.paymentMethod.reset();
		this.form.controls.paymentMethodTerm.reset();
	}

	private verifyIfIsMigratedAccountingAccounts(): void {
		this.persistenceUnitService
			.getMigratedAccountingAccounts()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((data) => {
				this.isMigratedAccountingAccounts.set(
					data.migratedAccountingAccounts
				);
			});
	}
}
