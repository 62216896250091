<atlas-section
	[header]="'CREATE_RECEIPT.SUMMARY_DATA_STEP.SUMMARY_HEADER' | translate"
>
	<atlas-summary-container>
		<atlas-summary-card
			[header]="
				'CREATE_RECEIPT.SUMMARY_DATA_STEP.RECEIPT_DATA_HEADER'
					| translate
			"
			[relatedStep]="steps.RECEIPT_DATA"
			[expandedVertically]="false"
		>
			<atlas-layout gap="9" justify="space-evenly">
				<atlas-layout>
					@if (!isLinkedTypeSelected()) {
						<atlas-summary-item
							[label]="
								'CREATE_RECEIPT.SUMMARY_DATA_STEP.RECEIPT_TYPE'
									| translate
							"
							[description]="
								'CREATE_RECEIPT.SUMMARY_DATA_STEP.SINGLE'
									| translate
							"
							data-testid="receipt-wizard-summary-receipt-type"
						></atlas-summary-item>

						<atlas-summary-item
							[label]="
								'CREATE_RECEIPT.SUMMARY_DATA_STEP.BANK'
									| translate
							"
							[description]="
								getFormControlValueBankAndReceiptTypeData(
									'bankName'
								)
							"
							data-testid="receipt-wizard-summary-receipt-bank"
						></atlas-summary-item>
					}
					<atlas-summary-item
						[label]="
							'CREATE_RECEIPT.SUMMARY_DATA_STEP.VALUE' | translate
						"
						[description]="getFormControlValueOfReceipt()"
						data-testid="receipt-wizard-summary-receipt-value"
					></atlas-summary-item>
					<atlas-summary-item
						[label]="getDueDateLabel()"
						[description]="
							getFormControlValueReceiptData('dueDate')
						"
						data-testid="receipt-wizard-summary-receipt-due-date"
					></atlas-summary-item>
					<atlas-summary-item
						[label]="
							'CREATE_RECEIPT.SUMMARY_DATA_STEP.PAYMENT_METHOD'
								| translate
						"
						[description]="getPaymentMethodName()"
						data-testid="receipt-wizard-summary-receipt-payment-method"
					></atlas-summary-item>
					<atlas-summary-item
						[label]="
							'CREATE_RECEIPT.SUMMARY_DATA_STEP.CATEGORY'
								| translate
						"
						[description]="
							getFormControlValueReceiptData(
								'accountingAccountName'
							)
						"
						data-testid="receipt-wizard-summary-receipt-category"
					></atlas-summary-item>
					<atlas-summary-item
						[label]="
							'CREATE_RECEIPT.SUMMARY_DATA_STEP.COST_CENTER'
								| translate
						"
						[description]="
							getFormControlValueReceiptData('costCenterName')
						"
						data-testid="receipt-wizard-summary-receipt-cost-center"
					></atlas-summary-item>
					<atlas-summary-item
						[label]="
							'CREATE_RECEIPT.SUMMARY_DATA_STEP.PROJECT'
								| translate
						"
						[description]="
							getFormControlValueReceiptData('projectName')
						"
						data-testid="receipt-wizard-summary-receipt-project"
					></atlas-summary-item>
				</atlas-layout>
				<atlas-layout aligment="bottom">
					<atlas-divider></atlas-divider>
					@if (isBankAsaas()) {
						@for (
							billingType of getBillingTypeEnumValue();
							track $index
						) {
							@if (billingType) {
								<atlas-summary-item
									[popoverTitle]="getPopoverTitle($index)"
									[popoverContent]="getPopoverContent($index)"
									horizontal=""
									[label]="getPaymentMethodLabel()"
									[labelHighlight]="
										getPaymentMethodLabelHighlight($index)
									"
									[description]="
										$index === 0
											? bankSlipPixNetValue()
											: creditCardNetValue()
									"
									data-testid="receipt-wizard-summary-billing-type"
								></atlas-summary-item>
							}
						}
					} @else {
						<atlas-summary-item
							horizontal=""
							[label]="getOtherBanksBillingTypeLabel()"
							[labelHighlight]="getPaymentMethodName()"
							[description]="valueToReceipt()"
							data-testid="receipt-wizard-summary-payment-method"
						></atlas-summary-item>
					}
				</atlas-layout>
			</atlas-layout>
		</atlas-summary-card>

		@if (isLinkedTypeSelected()) {
			<atlas-summary-card
				[header]="
					'CREATE_RECEIPT.SUMMARY_DATA_STEP.LINKED_DATA_HEADER'
						| translate
				"
				[relatedStep]="steps.BANK_AND_RECEIPT_TYPE_DATA"
			>
				<atlas-summary-item
					[label]="
						'CREATE_RECEIPT.SUMMARY_DATA_STEP.RECEIPT_TYPE'
							| translate
					"
					[description]="
						getFormControlValueBankAndReceiptTypeData(
							'linkedToggleSelected'
						)
							? ('CREATE_RECEIPT.SUMMARY_DATA_STEP.LINKED'
								| translate)
							: ('CREATE_RECEIPT.SUMMARY_DATA_STEP.SINGLE'
								| translate)
					"
					data-testid="receipt-wizard-summary-receipt-type"
				></atlas-summary-item>
				<atlas-grid>
					<atlas-row>
						<atlas-col lg="6">
							<atlas-summary-item
								[label]="
									'CREATE_RECEIPT.SUMMARY_DATA_STEP.LINKED_TYPE'
										| translate
								"
								[description]="getLinkedTypeName()"
								data-testid="receipt-wizard-summary-linked-type"
							></atlas-summary-item>
						</atlas-col>
						<atlas-col lg="6">
							<atlas-summary-item
								[label]="
									'CREATE_RECEIPT.SUMMARY_DATA_STEP.LINKED_ITEM'
										| translate
								"
								[description]="
									'Nº ' +
									getFormControlValueBankAndReceiptTypeData(
										'documentNumber'
									)
								"
								data-testid="receipt-wizard-summary-linked-item"
							></atlas-summary-item>
						</atlas-col>
					</atlas-row>
				</atlas-grid>
				<atlas-summary-item
					[label]="
						'CREATE_RECEIPT.SUMMARY_DATA_STEP.BANK' | translate
					"
					[description]="
						getFormControlValueBankAndReceiptTypeData('bankName')
					"
					data-testid="receipt-wizard-summary-receipt-bank"
				></atlas-summary-item>
			</atlas-summary-card>
		}

		<atlas-summary-card
			[header]="
				'CREATE_RECEIPT.SUMMARY_DATA_STEP.CUSTOMER_DATA_HEADER'
					| translate
			"
			[relatedStep]="steps.CUSTOMER_DATA"
		>
			<atlas-summary-item
				[label]="
					'CREATE_RECEIPT.SUMMARY_DATA_STEP.CUSTOMER_NAME' | translate
				"
				[description]="getFormControlValueCustomerData('legalName')"
				data-testid="receipt-wizard-summary-legal-name"
			></atlas-summary-item>
			<atlas-summary-item
				label="CPF/CNPJ"
				[description]="
					getFormControlValueCustomerData(
						'companyTaxpayerRegistration'
					) | taxpayerRegistration
				"
				data-testid="receipt-wizard-summary-company-taxpayer-registration"
			></atlas-summary-item>
			<atlas-summary-item
				label="E-mail"
				[description]="getFormControlValueCustomerData('email')"
				data-testid="receipt-wizard-summary-company-email"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'CREATE_RECEIPT.SUMMARY_DATA_STEP.CELLPHONE' | translate
				"
				[description]="
					getFormControlValueCustomerData('cellphone')
						| cellphoneNumber
				"
				data-testid="receipt-wizard-summary-cell-phone"
			></atlas-summary-item>
		</atlas-summary-card>

		@if (showNotificationCard && isBankAsaas()) {
			<atlas-summary-card
				[header]="
					'CREATE_RECEIPT.SUMMARY_DATA_STEP.NOTIFICATIONS_CARD_HEADER'
						| translate
				"
				[relatedStep]="steps.CUSTOMER_DATA"
			>
				<atlas-layout gap="9" auto="true">
					<atlas-layout justify="start" class="card-container">
						@if (
							getFormControlValueNotifications(
								"emailNotification"
							) ||
							getFormControlValueNotifications("smsNotification")
						) {
							<atlas-summary-item
								[popoverTitle]="
									'CREATE_RECEIPT.SUMMARY_DATA_STEP.EMAIL_SMS_POPOVER_TITLE'
										| translate
								"
								[popoverContent]="messagingFeePopoverContent()"
								[label]="
									'CREATE_RECEIPT.SUMMARY_DATA_STEP.EMAIL_SMS_LABEL'
										| translate
								"
								[description]="
									messagingFeeNotificationDescription()
								"
								data-testid="receipt-wizard-summary-email-sms"
							></atlas-summary-item>
						}

						@if (
							getFormControlValueNotifications(
								"whatsappNotification"
							)
						) {
							<atlas-summary-item
								[popoverTitle]="
									'CREATE_RECEIPT.SUMMARY_DATA_STEP.WHATSAPP_POPOVER_TITLE'
										| translate
								"
								[popoverContent]="whatsAppFeePopoverContent()"
								[label]="
									'CREATE_RECEIPT.SUMMARY_DATA_STEP.WHATSAPP_LABEL'
										| translate
								"
								[description]="
									whatsAppFeeNotificationDescription()
								"
								data-testid="receipt-wizard-summary-whatsapp-notification"
							></atlas-summary-item>
						}
					</atlas-layout>

					@if (showNotificationFeedbackMessage) {
						<atlas-layout justify="end" class="bottom-layout">
							<atlas-text
								muted
								[innerHTML]="notificationFeedbackMessage"
							></atlas-text>
						</atlas-layout>
					}
				</atlas-layout>
			</atlas-summary-card>
		}
	</atlas-summary-container>
</atlas-section>
