import { Component, Input, OnDestroy, OnInit, signal } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Notification } from "@data/schemas/notification/notification";
import { NotificationService } from "@data/services/notification.service";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotificationsForm } from "../types/notifications-form";
import { BanksNameEnum } from "@enums/banks-name.enum";
import {
	BankAndReceiptTypeDataFormGroup,
	CustomerDataFormGroup,
	ReceiptDataFormGroup
} from "../types/form";
import { Steps } from "../types/steps.enum";

@Component({
	selector: "app-summary-data-step",
	templateUrl: "./summary-data-step.component.html",
	styleUrl: "./summary-data-step.component.scss",
	standalone: false
})
export class SummaryDataStepComponent implements OnInit, OnDestroy {
	private _onDestroy = new Subject<boolean>();
	@Input() receiptForm: FormGroup | null = null;
	@Input() interactionId: string = "";

	readonly INSTALLMENT_COUNT_ONE = 1;
	readonly BANK_SLIP = 0;
	readonly CREDIT_CARD = 1;
	readonly BRL_ZERO_STRING = "R$ ";

	protected valueToReceipt = signal("");
	protected bankSlipPixNetValue = signal("");
	protected creditCardNetValue = signal("");
	protected paymentMethodNameValue = signal("");
	protected whatsappNotificationFeeValue = "";
	protected emailSmsNotificationFeeValue = "";
	protected whatsappNotification = signal(false);
	protected emailNotification = signal(false);
	protected smsNotification = signal(false);
	protected isBankAsaas = signal(true);
	protected showNotificationFeedbackMessage = false;
	protected showNotificationCard = true;
	protected BANK_ASAAS = BanksNameEnum.ASAAS.toString();
	protected isLinkedTypeSelected = signal(true);

	protected notificationsBeforeWizard: Notification | undefined;

	protected defaultWhatsAppFeeNotificationDescription =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.WHATSAPP_DESCRIPTION"
		);

	protected defaultWhatsAppFeePopoverContent =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.WHATSAPP_POPOVER_CONTENT"
		);

	protected whatsAppFeeNotificationDescription = signal("");

	protected whatsAppFeePopoverContent = signal("");

	protected defaultMessagingFeeNotificationDescription =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.EMAIL_SMS_DESCRIPTION"
		);

	protected defaultMessagingFeePopoverContent =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.EMAIL_SMS_POPOVER_CONTENT"
		);

	protected messagingFeeNotificationDescription = signal("");

	protected messagingFeePopoverContent = signal("");

	protected notificationFeedbackMessage = "";

	protected baseNotificationFeedbackMessage =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.NOTIFICATIONS_FEEDBACK_MESSAGE"
		);

	steps = Steps;

	constructor(
		private automataTranslateService: AutomataTranslateService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.getUpdatedFormValues();
		this.handleNotificationContent();
		this.hideDisableNotificationsMessage();
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private getUpdatedFormValues(): void {
		this.isBankAsaas.set(
			this.bankAndReceiptTypeData.controls.bankName.value ===
				this.BANK_ASAAS
		);

		this.valueToReceipt.set(
			this.formatCurrencyToBrl(
				this.receiptData.controls.valueToReceive.value as number
			)
		);

		this.bankSlipPixNetValue.set(
			this.formatCurrencyToBrl(
				this.receiptData.controls.bankSlipPixNetValue.value as number
			)
		);

		this.creditCardNetValue.set(
			this.formatCurrencyToBrl(
				this.receiptData.controls.creditCardNetValue.value as number
			)
		);

		this.paymentMethodNameValue.set(
			this.receiptData.controls.paymentMethodName.value as string
		);

		this.isLinkedTypeSelected.set(
			this.bankAndReceiptTypeData.controls.linkedToggleSelected
				.value as boolean
		);
		this.updateWhatsappNotificationPopoversContentWithFees(
			this.customerData.controls.whatsappNotificationFee.value as number
		);
		this.updateEmailSmsNotificationPopoversContentWithFees(
			this.customerData.controls.emailSmsNotificationFee.value as number
		);
		this.getUpdateNotificationsFormControl();
	}

	private handleNotificationContent(): void {
		const idCustomer = Number(this.customerData.controls.idCustomer.value);

		if (idCustomer) {
			this.watchPreviousNotificationContent();
			return;
		}
		this.notificationsBeforeWizard = undefined;
	}

	private hideDisableNotificationsMessage(): void {
		const selectedToggle = this.customerData.controls.selectedToggle.value;

		if (selectedToggle === "newCustomer") {
			this.showNotificationFeedbackMessage = false;
			this.notificationsBeforeWizard = undefined;
		}
	}

	private getUpdateNotificationsFormControl() {
		this.whatsappNotification.set(
			this.customerData.controls.whatsappNotification.value as boolean
		);
		this.emailNotification.set(
			this.customerData.controls.emailNotification.value as boolean
		);
		this.smsNotification.set(
			this.customerData.controls.smsNotification.value as boolean
		);
		this.updateNotificationMethods();

		if (!this.verifyIfHasAnyNotificationEnabled()) {
			this.showNotificationCard = false;
			return;
		}

		this.showNotificationCard = true;
	}

	protected getFormControlValueCustomerData(name: string): string {
		if (!this.receiptForm?.controls.customerData.get(name)?.value) {
			return this.getUninformedTranslation();
		}

		return String(this.receiptForm?.controls.customerData.get(name)?.value);
	}

	protected getFormControlValueReceiptData(name: string): string {
		if (!this.receiptForm?.controls.receiptData.get(name)?.value) {
			return this.getUninformedTranslation();
		}

		return String(this.receiptForm?.controls.receiptData.get(name)?.value);
	}

	protected getFormControlValueBankAndReceiptTypeData(name: string): string {
		if (
			!this.receiptForm?.controls.bankAndReceiptTypeData.get(name)?.value
		) {
			return this.getUninformedTranslation();
		}

		return String(
			this.receiptForm?.controls.bankAndReceiptTypeData.get(name)?.value
		);
	}

	protected getFormControlMoneyValueReceiptData(name: string): string {
		if (!this.receiptForm?.controls.receiptData.get(name)?.value) {
			return this.getUninformedTranslation();
		}

		return this.formatCurrencyToBrl(
			Number(this.receiptForm?.controls.receiptData.get(name)?.value)
		);
	}

	protected getFormControlValueOfReceipt(): string {
		if (!this.getNumberValueOfField("valueToReceive")) {
			return this.getUninformedTranslation();
		}

		const numberInstallments =
			this.getNumberValueOfField("numberInstallments");
		const installmentsOf = this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.INSTALLMENTS_OF"
		);

		if (numberInstallments > this.INSTALLMENT_COUNT_ONE) {
			const value = String(this.receiptData.controls.totalValue.value);

			return `${numberInstallments} ${installmentsOf} ${this.valueToReceipt()} (R$ ${value})`;
		}

		return this.valueToReceipt();
	}

	private getNumberValueOfField(field: string): number {
		return Number(this.receiptForm?.controls.receiptData.get(field)?.value);
	}

	protected getPaymentMethodName(): string {
		const billingTypeEnum: boolean[] = this.getBillingTypeEnumValue();

		if (this.paymentMethodNameValue()) {
			return this.paymentMethodNameValue();
		}

		if (
			billingTypeEnum[this.BANK_SLIP] &&
			billingTypeEnum[this.CREDIT_CARD]
		) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.ASK_CUSTOMER"
			);
		}

		if (
			billingTypeEnum[this.BANK_SLIP] &&
			!billingTypeEnum[this.CREDIT_CARD]
		) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.BANK_SLIP"
			);
		}

		if (
			!billingTypeEnum[this.BANK_SLIP] &&
			billingTypeEnum[this.CREDIT_CARD]
		) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.CREDIT_CARD"
			);
		}

		return "";
	}

	protected getPaymentMethodLabel(): string {
		if (
			this.getNumberValueOfField("numberInstallments") >
			this.INSTALLMENT_COUNT_ONE
		) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.AMOUNT_RECEIVABLE_BY_INSTALLMENT_IN"
			);
		}

		return this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.AMOUNT_RECEIVABLE_IN"
		);
	}

	protected getPaymentMethodLabelHighlight(index: number): string {
		const billingTypeEnum: boolean[] = this.getBillingTypeEnumValue();

		if (index === this.BANK_SLIP && billingTypeEnum[index]) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.BANK_SLIP"
			);
		}

		if (index === this.CREDIT_CARD && billingTypeEnum[index]) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.CREDIT_CARD"
			);
		}

		return "";
	}

	protected getNetValue(index: number): string {
		if (this.BANK_SLIP === index) {
			return this.formatCurrencyToBrl(
				this.getNumberValueOfField("bankSlipPixNetValue")
			);
		}

		if (this.CREDIT_CARD === index) {
			return this.formatCurrencyToBrl(
				this.getNumberValueOfField("creditCardNetValue")
			);
		}

		return "";
	}

	protected getPopoverTitle(index: number): string {
		if (this.BANK_SLIP === index) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.TAXES_BANK_SLIP"
			);
		}

		if (this.CREDIT_CARD === index) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.TAXES_CREDIT_CARD"
			);
		}

		return "";
	}

	protected getPopoverContent(index: number): string {
		if (this.BANK_SLIP === index) {
			return String(
				this.receiptForm?.controls.receiptData.get(
					"bankSlipPopoverContent"
				)?.value
			);
		}

		if (this.CREDIT_CARD === index) {
			return String(
				this.receiptForm?.controls.receiptData.get(
					"creditCardPopoverContent"
				)?.value
			);
		}

		return "";
	}

	protected getBillingTypeEnumValue(): boolean[] {
		return this.receiptData.controls.billingTypeEnum.getRawValue();
	}

	protected getDueDateLabel(): string {
		const numberInstallments =
			this.getNumberValueOfField("numberInstallments");

		if (numberInstallments > this.INSTALLMENT_COUNT_ONE) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.DUE_DATE_INSTALLMENT"
			);
		}

		return this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.DUE_DATE"
		);
	}

	protected getFormControlValueNotifications(name: string): boolean {
		return Boolean(
			this.receiptForm?.controls.customerData.get(name)?.value
		);
	}

	private getUninformedTranslation(): string {
		return this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.UNINFORMED"
		);
	}

	private formatCurrencyToBrl(value: number): string {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL"
		}).format(value);
	}

	private updateWhatsappNotificationPopoversContentWithFees(
		whatsAppFee: number
	) {
		const formattedWhatsAppFee =
			whatsAppFee !== 0 ? this.formatCurrencyToBrl(whatsAppFee) : "0,00";

		this.whatsAppFeePopoverContent.set(
			this.defaultWhatsAppFeePopoverContent.replace(
				"{value}",
				`${`<strong>` + formattedWhatsAppFee + `</strong>`}`
			)
		);

		this.whatsAppFeeNotificationDescription.set(
			this.defaultWhatsAppFeeNotificationDescription.replace(
				"{value}",
				formattedWhatsAppFee
			)
		);
	}

	private updateEmailSmsNotificationPopoversContentWithFees(
		messagingFee: number
	) {
		const formattedMessagingFee =
			messagingFee !== 0
				? this.formatCurrencyToBrl(messagingFee)
				: "0,00";

		this.messagingFeePopoverContent.set(
			this.defaultMessagingFeePopoverContent.replace(
				"{value}",
				`${`<strong>` + formattedMessagingFee + `</strong>`}`
			)
		);

		this.messagingFeeNotificationDescription.set(
			this.defaultMessagingFeeNotificationDescription.replace(
				"{value}",
				formattedMessagingFee
			)
		);
	}

	private watchPreviousNotificationContent(): void {
		this.notificationService.currentNotification$
			.pipe(takeUntil(this._onDestroy))
			.subscribe(
				(notification) =>
					(this.notificationsBeforeWizard = notification)
			);
	}

	private updateNotificationMethods(): void {
		this.showNotificationFeedbackMessage = false;

		if (!this.notificationsBeforeWizard) {
			return;
		}

		const notificationTypes = ["email", "sms", "whatsapp"];
		let notificationMethods = "";

		for (const type of notificationTypes) {
			if (
				this.notificationsBeforeWizard?.[
					`${type}EnabledForCustomer` as keyof Notification
				] &&
				!this[`${type}Notification` as keyof NotificationsForm]()
			) {
				this.showNotificationFeedbackMessage = true;
				if (type === "whatsapp") {
					notificationMethods += "WhatsApp, ";
				}

				if (type === "email") {
					notificationMethods += "Email, ";
				}

				if (type === "sms") {
					notificationMethods += "SMS, ";
				}
			}
		}

		if (notificationMethods) {
			this.notificationFeedbackMessage =
				this.baseNotificationFeedbackMessage.replace(
					"{value}",
					notificationMethods.slice(0, -2)
				);
		}
	}

	private verifyIfHasAnyNotificationEnabled(): boolean | undefined {
		return (
			this.whatsappNotification() ||
			this.emailNotification() ||
			this.smsNotification() ||
			this.notificationsBeforeWizard?.emailEnabledForCustomer ||
			this.notificationsBeforeWizard?.smsEnabledForCustomer ||
			this.notificationsBeforeWizard?.whatsappEnabledForCustomer
		);
	}

	protected getOtherBanksBillingTypeLabel(): string {
		const numberInstallments =
			this.getNumberValueOfField("numberInstallments");

		if (numberInstallments > this.INSTALLMENT_COUNT_ONE) {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.SUMMARY_DATA_STEP.OTHER_BANKS.AMOUNT_RECEIVABLE_BY_INSTALLMENT_IN"
			);
		}

		return this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.OTHER_BANKS.AMOUNT_RECEIVABLE_IN"
		);
	}

	protected getLinkedTypeName(): string {
		const type =
			this.getFormControlValueBankAndReceiptTypeData("linkedType");

		if (type === "salesOrder") {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.SALES_ORDER"
			);
		}

		if (type === "serviceOrder") {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.SERVICE_ORDER"
			);
		}

		if (type === "invoice") {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.INVOICE"
			);
		}

		if (type === "serviceInvoice") {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.SERVICE_INVOICE"
			);
		}

		if (type === "invoiceRental") {
			return this.automataTranslateService.getTranslationSync(
				"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.INVOICE_RENTAL"
			);
		}

		return "";
	}

	get bankAndReceiptTypeData(): FormGroup<BankAndReceiptTypeDataFormGroup> {
		return this.receiptForm?.get(
			Steps.BANK_AND_RECEIPT_TYPE_DATA
		) as FormGroup<BankAndReceiptTypeDataFormGroup>;
	}

	get receiptData(): FormGroup<ReceiptDataFormGroup> {
		return this.receiptForm?.get(
			Steps.RECEIPT_DATA
		) as FormGroup<ReceiptDataFormGroup>;
	}

	get customerData(): FormGroup<CustomerDataFormGroup> {
		return this.receiptForm?.get(
			Steps.CUSTOMER_DATA
		) as FormGroup<CustomerDataFormGroup>;
	}
}
