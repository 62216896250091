<atlas-form id="managementOrganizationDetails" [formGroup]="form">
	<atlas-grid>
		<atlas-row class="row-section-margin">
			<atlas-col lg="12">
				<atlas-section
					[header]="
						'CREATE_RECEIPT.RECEIPT_DATA_STEP.MANAGEMENT_ORGANIZATION'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="12">
								<div class="section-description">
									{{
										"CREATE_RECEIPT.RECEIPT_DATA_STEP.MANAGEMENT_ORGANIZATION_DESCRIPTION"
											| translate
									}}
								</div>
							</atlas-col>
						</atlas-row>

						<atlas-row>
							<atlas-col lg="4">
								<atlas-select
									appAtlasFormElement
									formControlName="accountingAccount"
									data-testid="receipt-wizard-organization-accounting-account"
									[value]="defaultAccountingAccount?.id"
									[defaultValue]="
										defaultAccountingAccount?.id
									"
									[required]="true"
									(atlas-select-change)="
										getSelectedAccountingAccount()
									"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.CATEGORY'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.PLACEHOLDER.CATEGORY'
											| translate
									"
									[enableSearch]="true"
								>
									@if (accountingAccounts) {
										@for (
											accountingAccount of accountingAccounts;
											track accountingAccount.id
										) {
											<atlas-option
												[label]="
													getCodeAndSeparatingHyphen(
														accountingAccount.codigo
													) +
													accountingAccount.nomeConta
												"
												[value]="accountingAccount.id"
											></atlas-option>
										}
									}
								</atlas-select>
							</atlas-col>
							<atlas-col lg="4">
								<atlas-select
									appAtlasFormElement
									formControlName="costCenter"
									data-testid="receipt-wizard-organization-cost-center"
									(atlas-select-change)="
										getSelectedCostCenter()
									"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.COST_CENTER'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.PLACEHOLDER.COST_CENTER'
											| translate
									"
									[enableSearch]="true"
								>
									@if (costCenters) {
										@for (
											costCenter of costCenters;
											track costCenter.id
										) {
											<atlas-option
												[label]="costCenter.descricao"
												[value]="costCenter.id"
											></atlas-option>
										}
									}
								</atlas-select>
							</atlas-col>
							<atlas-col lg="4">
								<atlas-select
									appAtlasFormElement
									formControlName="project"
									data-testid="receipt-wizard-organization-project"
									(atlas-select-change)="getSelectedProject()"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.PROJECT'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.PLACEHOLDER.PROJECT'
											| translate
									"
									[enableSearch]="true"
								>
									@if (projects) {
										@for (
											project of projects;
											track project.id
										) {
											<atlas-option
												[label]="project.descricao"
												[value]="project.id"
											></atlas-option>
										}
									}
								</atlas-select>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
			</atlas-col>
		</atlas-row>
	</atlas-grid>
</atlas-form>
