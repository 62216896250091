import {
	Component,
	ElementRef,
	Inject,
	Input,
	LOCALE_ID,
	OnChanges,
	OnDestroy,
	OnInit,
	ViewChild,
	signal
} from "@angular/core";
import { AtlasToggleItemChangeEvent } from "../types/atlas-toggle-item-change-event";
import { CompanyService } from "@data/services/company.service";
import { Subject, combineLatest, pipe, takeUntil } from "rxjs";
import {
	FormGroup,
	FormGroupDirective,
	ValidationErrors,
	ValidatorFn
} from "@angular/forms";
import { CustomerDataFormGroup } from "../types/form";
import { Company } from "@data/schemas/company";
import { CepData } from "@data/schemas/cep-data";
import { CepService } from "@data/services/cep.service";
import { CityService } from "@data/services/city.service";
import { CityData } from "@data/schemas/city-data";
import { AlertsService } from "@shared/components/atlas/alerts/services";
import { GLOBAL_ALERT_KEY } from "@shared/components/atlas/alerts/types";
import { IAtlasMaskedInput } from "@shared/types/atlas/i-atlas-masked-input";
import { IAtlasCheckbox } from "@shared/types/atlas/i-atlas-checkbox";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { NotificationService } from "@data/services/notification.service";
import { NotificationFees } from "@data/schemas/notification/notification-fees";
import { Notification } from "@data/schemas/notification/notification";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { formatCompanyTaxpayerRegistration } from "@shared/utils/string-util";
import { IAtlasSelect } from "@shared/types/atlas";
import { AtlasSelectProperties } from "@shared/components/atlas/types/atlas-select-properties";
import { BanksNameEnum } from "@enums/banks-name.enum";
import { Steps } from "../types/steps.enum";

@Component({
	selector: "app-customer-data-step",
	templateUrl: "./customer-data-step.component.html",
	styleUrl: "./customer-data-step.component.scss",
	standalone: false
})
export class CustomerDataStepComponent implements OnInit, OnChanges, OnDestroy {
	private _onDestroy = new Subject<boolean>();
	private lastClickedCheckbox: string | null = null;
	private selectedLinkedItem: number | null = null;

	protected selectedToggle = "registeredCustomer";
	protected form!: FormGroup<CustomerDataFormGroup>;
	protected cities: CityData[] = [];
	protected loadingPostalCode = false;
	protected loadingSelectedCustomerData = true;
	protected noCityFoundByZipCode = false;
	protected notificationsBeforeWizard: Notification | undefined;
	protected customerLength = 0;
	protected isBankAsaas = false;
	protected isLinkedType = false;

	protected defaultWhatsAppFeePopoverContent =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.CUSTOMER_DATA_STEP.WHATSAPP_POPOVER"
		);

	protected whatsAppFeePopoverContent = signal("");

	protected defaultMessagingFeePopoverContent =
		this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.CUSTOMER_DATA_STEP.EMAIL_SMS_POPOVER"
		);

	protected messagingFeePopoverContent = signal("");

	@ViewChild("emailInput")
	protected emailInput!: ElementRef<IAtlasMaskedInput>;

	@ViewChild("cellphoneInput")
	protected cellphoneInput!: ElementRef<IAtlasMaskedInput>;

	@ViewChild("emailNotificationCheckbox")
	protected emailNotificationCheckbox!: ElementRef<IAtlasCheckbox>;

	@ViewChild("whatsappNotificationCheckbox")
	protected whatsappNotificationCheckbox!: ElementRef<IAtlasCheckbox>;

	@ViewChild("smsNotificationCheckbox")
	protected smsNotificationCheckbox!: ElementRef<IAtlasCheckbox>;

	@ViewChild("taxpayerRegistrationInput")
	protected taxpayerRegistrationInput!: ElementRef<IAtlasMaskedInput>;

	@ViewChild("atlasSelectCity")
	protected atlasSelectCity!: ElementRef<IAtlasSelect>;

	@ViewChild("atlasSelectCustomer")
	protected atlasSelectCustomer!: ElementRef<IAtlasSelect>;

	@Input()
	currentStep: string = "";

	@Input() interactionId: string = "";

	@Input()
	public formGroupName!: string;

	@Input() bankTypeForm: FormGroup | null = null;

	readonly BRL_ZERO_STRING = "R$ ";

	protected searchCustomersUrl =
		this.customerService.getSearchCompaniesWithSearchTextUrl();

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private customerService: CompanyService,
		private rootFormGroup: FormGroupDirective,
		private cepService: CepService,
		private cityService: CityService,
		private alertsService: AlertsService,
		private automataTranslateService: AutomataTranslateService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<CustomerDataFormGroup>;

		this.form.controls.companyTaxpayerRegistration.setValidators(
			this.taxpayerRegistrationAlreadyExists()
		);

		this.updateTaxpayerRegistrationValidation();
		this.getCompanyCountByTaxpayerRegistration();
		this.getListOfCities();
		this.enableNotificationCheckboxesWhenFieldIsFilled();
		this.updateEmailNotificationFilledInValidatorStatusMessage();
		this.updateSmsAndWhatsappNotificationFilledInValidatorStatusMessage();
	}

	ngOnChanges(): void {
		this.isBankAsaas =
			String(this.bankTypeForm?.controls.bankName.value) ===
			BanksNameEnum.ASAAS.toString();

		if (this.currentStep === Steps.CUSTOMER_DATA.toString()) {
			this.getNotificationFeesFromAsaas();
			this.changeCustomerDataFieldsBySingleOrLinkedReceivableType();
		}
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	protected registeredCustomerToggleChecked(event: Event): string {
		const atlasItemToggleChangeEvent = event as AtlasToggleItemChangeEvent;
		this.noCityFoundByZipCode = false;
		this.form.controls.city.disable();

		if (atlasItemToggleChangeEvent.detail.checked) {
			this.selectedToggle = "registeredCustomer";
			this.cleanCustomerData();
			if (!this.isLinkedType) {
				this.form.controls.companyTaxpayerRegistration.clearValidators();
			}
			return this.selectedToggle;
		}

		this.cleanCustomerData();
		this.resetNotificationCheckboxes();
		this.form.controls.companyTaxpayerRegistration.setValidators(
			this.taxpayerRegistrationAlreadyExists()
		);
		this.form.controls.selectedToggle.setValue("newCustomer");
		return (this.selectedToggle = "newCustomer");
	}

	protected getSelectedCustomer(): void {
		this.loadingSelectedCustomerData = true;
		const selectedOption: AtlasSelectProperties =
			this.atlasSelectCustomer.nativeElement?.getSelectedOptions();
		const selectedCustomer = selectedOption.customProperties as Company;

		if (selectedCustomer) {
			this.getPreviousNotificationContent(selectedCustomer.id);
			this.patchFormValues(selectedCustomer);
		}
		this.notificationCheckboxesControlInRegisteredCustomerToggle();
	}

	protected getAddressByPostalCode(): void {
		this.loadingPostalCode = true;
		const cepFormatted = this.form.controls.postalCode.value
			? this.form.controls.postalCode.value.replace(/[^0-9]/g, "")
			: "";

		if (cepFormatted.length !== 8) {
			this.loadingPostalCode = false;
			this.cleanAddressData();
			return;
		}

		this.cepService
			.getCepData(cepFormatted)
			.pipe(takeUntil(this._onDestroy))
			.subscribe({
				next: (cepData) => {
					if (cepData?.erro) {
						this.form.controls.city.enable();
						this.cleanAddressData();
						this.loadingPostalCode = false;
						this.noCityFoundByZipCode = true;
						return;
					}
					this.noCityFoundByZipCode = false;
					this.form.controls.city.disable();
					this.patchCepFormValues(cepData);
					this.loadingPostalCode = false;
				},
				error: () => {
					this.cleanAddressData();
					this.loadingPostalCode = false;
					this.noCityFoundByZipCode = true;
					this.form.controls.city.enable();
					return;
				}
			});
		return;
	}

	protected showAlertWhenCheckboxIsUnchecked(notificationType: string): void {
		if (this.selectedToggle === "newCustomer") {
			return;
		}

		if (this.lastClickedCheckbox === notificationType) {
			if (notificationType === "whatsappNotification") {
				this.alertsService.warning({
					key: GLOBAL_ALERT_KEY,
					message: this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.CUSTOMER_DATA_STEP.UNCHECK_WHATSAPP_NOTIFICATION_WARNING_MESSAGE"
					)
				});
			}

			if (notificationType === "emailNotification") {
				this.alertsService.warning({
					key: GLOBAL_ALERT_KEY,
					message: this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.CUSTOMER_DATA_STEP.UNCHECK_EMAIL_NOTIFICATION_WARNING_MESSAGE"
					)
				});
			}

			if (notificationType === "smsNotification") {
				this.alertsService.warning({
					key: GLOBAL_ALERT_KEY,
					message: this.automataTranslateService.getTranslationSync(
						"CREATE_RECEIPT.CUSTOMER_DATA_STEP.UNCHECK_SMS_NOTIFICATION_WARNING_MESSAGE"
					)
				});
			}
		}
		this.lastClickedCheckbox = null;
	}

	protected resetEmailAndCellphoneControls(): void {
		this.form.controls.email.reset("");
		this.form.controls.phone.reset("");
		return;
	}

	protected handleClick(checkboxName: string): void {
		this.lastClickedCheckbox = checkboxName;
		return;
	}

	protected getCompanyCountByTaxpayerRegistration(): void {
		this.form.controls.companyTaxpayerRegistration.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe((data) => {
				if (
					this.selectedToggle === "registeredCustomer" &&
					!this.isLinkedType
				) {
					return;
				}

				if (data) {
					const companyTaxpayerRegistration =
						formatCompanyTaxpayerRegistration(
							this.form.controls.companyTaxpayerRegistration
								.value!
						);

					const params: HttpParamsConfig = {
						fields: [
							{
								key: "cnpj",
								value: companyTaxpayerRegistration
							}
						]
					};

					if (this.validateTaxId(companyTaxpayerRegistration)) {
						this.customerService
							.getAllCompanies(params)
							.pipe(takeUntil(this._onDestroy))
							.subscribe(
								(data) => (this.customerLength = data.length)
							);
					} else {
						this.customerLength = 0;
					}

					this.researchCompanyByTaxpayerRegistration(
						companyTaxpayerRegistration
					);
				}
			});
	}

	protected researchCompanyByTaxpayerRegistration(
		taxpayerRegistration: string
	): void {
		if (taxpayerRegistration.length === 14 && !this.isLinkedType) {
			this.customerService
				.researchCnpj(taxpayerRegistration)
				.subscribe((data: unknown) => {
					const companyData = data as Company;
					if (companyData?.cnpj) {
						this.patchFormValues(companyData);
						this.getAddressByPostalCode();
					}
				});
		}
	}

	protected getSelectedCityAndSetUf(): void {
		const selectedCity: AtlasSelectProperties =
			this.atlasSelectCity.nativeElement?.getSelectedOptions();
		const uf = selectedCity.label.split("-")[1].trim();
		this.form.controls.uf.patchValue(uf);
	}

	private patchFormValues(customer: Company): void {
		this.form.patchValue({
			legalName: customer.razaoSocial || "",
			companyTaxpayerRegistration: customer.cnpj || "",
			email: customer.email || "",
			phone: this.formatPhone(customer.telefone1, customer.dd1),
			companyName: customer.nomeFantasia || "",
			cellphone: this.formatPhone(customer.telefone2, customer.dd2),
			postalCode: customer.cep || "",
			city: customer.cidade ? customer.cidade : "",
			neighborhood: customer.bairro || "",
			address: customer.endereco || "",
			addressNumber: customer.numero || "",
			complement: customer.complemento || "",
			uf: customer.uf || ""
		});

		if (customer.cidade) {
			this.form.controls.city.disable();
		}
		this.loadingSelectedCustomerData = false;
		return;
	}

	private patchNotificationFeesOnReactiveForm(
		notificationFees: NotificationFees
	): void {
		this.form.patchValue({
			emailSmsNotificationFee: notificationFees.messagingFeeValue,
			whatsappNotificationFee: notificationFees.whatsAppFeeValue
		});
	}

	private formatPhone(phone: string, dd: string): string {
		return dd && phone ? `${dd}${phone}` : "";
	}

	private cleanCustomerData(): void {
		this.form.reset({
			idCustomer: 0,
			companyTaxpayerRegistration: "",
			legalName: "",
			email: "",
			phone: "",
			cellphone: "",
			postalCode: "",
			companyName: ""
		});

		this.cleanAddressData();
	}

	private cleanAddressData(): void {
		this.form.controls.city.patchValue("");
		this.form.controls.address.reset();
		this.form.controls.complement.reset();
		this.form.controls.neighborhood.reset();
		this.form.controls.addressNumber.reset();
		this.form.controls.uf.reset();
		this.form.controls.ibgeCode.reset();
	}

	private resetNotificationCheckboxes(): void {
		this.form.controls.emailNotification.enable();
		this.form.controls.emailNotification.patchValue(true);
		this.form.controls.whatsappNotification.enable();
		this.form.controls.whatsappNotification.patchValue(true);
		this.form.controls.smsNotification.enable();
		this.form.controls.smsNotification.patchValue(true);
	}

	private patchCepFormValues(cepData: CepData): void {
		this.form.controls.city.patchValue(cepData.localidade);
		this.form.controls.address.patchValue(cepData.logradouro);
		this.form.controls.complement.patchValue(cepData.complemento);
		this.form.controls.neighborhood.patchValue(cepData.bairro);
		this.form.controls.uf.patchValue(cepData.uf);
		this.form.controls.ibgeCode.patchValue(cepData.ibge);
	}

	private getListOfCities(): void {
		this.cityService
			.getCities()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((data) => {
				this.cities = data;
			});
	}

	private notificationCheckboxesControlInRegisteredCustomerToggle(): void {
		if (
			this.selectedToggle === "registeredCustomer" &&
			this.form.controls.idCustomer.value
		) {
			if (!this.form.controls.cellphone.value) {
				this.form.controls.smsNotification.disable();
				this.form.controls.whatsappNotification.disable();
			}

			if (!this.form.controls.email.value) {
				this.form.controls.emailNotification.disable();
			}
		}
	}

	private enableNotificationCheckboxesWhenFieldIsFilled(): void {
		if (this.selectedToggle === "registeredCustomer") {
			this.form.controls.cellphone.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					if (this.form.controls.cellphone.value) {
						this.form.controls.smsNotification.enable();
						this.form.controls.whatsappNotification.enable();
					}
				});

			this.form.controls.email.valueChanges
				.pipe(takeUntil(this._onDestroy))
				.subscribe(() => {
					if (this.form.controls.email.value) {
						this.form.controls.emailNotification.enable();
					}
				});
		}
	}

	private updateEmailNotificationFilledInValidatorStatusMessage(): void {
		combineLatest([
			this.form.controls.email.valueChanges,
			this.form.controls.emailNotification.valueChanges
		])
			.pipe(takeUntil(this._onDestroy))
			.subscribe(([email, emailNotification]) => {
				if (!emailNotification || (!email && !emailNotification)) {
					this.emailInput?.nativeElement.setStatusWithMessage(
						"none",
						""
					);

					this.emailNotificationCheckbox?.nativeElement.setStatusWithMessage(
						"none",
						""
					);
					return;
				}
			});
	}

	private updateSmsAndWhatsappNotificationFilledInValidatorStatusMessage(): void {
		combineLatest([
			this.form.controls.cellphone.valueChanges,
			this.form.controls.whatsappNotification.valueChanges,
			this.form.controls.smsNotification.valueChanges
		])
			.pipe(takeUntil(this._onDestroy))
			.subscribe(([telefone2, whatsappNotification, smsNotification]) => {
				if (
					telefone2 ||
					(!whatsappNotification && !smsNotification && !telefone2)
				) {
					this.cellphoneInput?.nativeElement.setStatusWithMessage(
						"none",
						""
					);

					this.whatsappNotificationCheckbox?.nativeElement.setStatusWithMessage(
						"none",
						""
					);

					this.smsNotificationCheckbox?.nativeElement.setStatusWithMessage(
						"none",
						""
					);

					return;
				}

				if (telefone2 || (!telefone2 && !whatsappNotification)) {
					this.whatsappNotificationCheckbox?.nativeElement.setStatusWithMessage(
						"none",
						""
					);

					return;
				}

				if (telefone2 || (!telefone2 && !smsNotification)) {
					this.smsNotificationCheckbox?.nativeElement.setStatusWithMessage(
						"none",
						""
					);

					return;
				}
			});
	}

	private getNotificationFeesFromAsaas() {
		if (
			this.form.controls.emailSmsNotificationFee.value &&
			this.form.controls.whatsappNotificationFee.value
		) {
			return;
		}

		if (this.isBankAsaas) {
			this.notificationService
				.getNotificationFeesFromAsaas()
				.pipe(takeUntil(this._onDestroy))
				.subscribe((data) => {
					if (data) {
						this.patchNotificationFeesOnReactiveForm(data);
						this.updateNotificationPopoversContentWithFees(
							data.whatsAppFeeValue,
							data.messagingFeeValue
						);
					}
				});
		}
	}

	private updateNotificationPopoversContentWithFees(
		whatsAppFee: number,
		messagingFee: number
	) {
		const formattedWhatsAppFee =
			whatsAppFee !== 0
				? String(Intl.NumberFormat(this.locale).format(whatsAppFee))
				: "0,00";

		const formattedMessagingFee =
			messagingFee !== 0
				? String(Intl.NumberFormat(this.locale).format(messagingFee))
				: "0,00";

		this.whatsAppFeePopoverContent.set(
			this.defaultWhatsAppFeePopoverContent.replace(
				"{value}",
				`${
					`<strong>` +
					this.BRL_ZERO_STRING +
					formattedWhatsAppFee +
					`</strong>`
				}`
			)
		);

		this.messagingFeePopoverContent.set(
			this.defaultMessagingFeePopoverContent.replace(
				"{value}",
				`${
					`<strong>` +
					this.BRL_ZERO_STRING +
					formattedMessagingFee +
					`</strong>`
				}`
			)
		);
	}

	private getPreviousNotificationContent(idCustomer: number): void {
		this.notificationService
			.getAllNotificationsByCompany(Number(idCustomer))
			.pipe(pipe(takeUntil(this._onDestroy)))
			.subscribe((notifications) => {
				this.notificationsBeforeWizard = notifications;
				this.form.controls.emailNotification.setValue(
					notifications.emailEnabledForCustomer
				);
				this.form.controls.whatsappNotification.setValue(
					notifications.whatsappEnabledForCustomer
				);
				this.form.controls.smsNotification.setValue(
					notifications.smsEnabledForCustomer
				);
				this.notificationService.changeNotification(
					this.notificationsBeforeWizard
				);
			});

		return;
	}

	private taxpayerRegistrationAlreadyExists(): ValidatorFn {
		return (): ValidationErrors | null => {
			if (this.customerLength > 0) {
				return { taxpayerRegistrationAlreadyExists: true };
			}

			return null;
		};
	}

	private updateTaxpayerRegistrationValidation(): void {
		this.form.controls.companyTaxpayerRegistration.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe((taxpayerRegistration) => {
				formatCompanyTaxpayerRegistration(taxpayerRegistration!);
				if (this.customerLength === 0) {
					if (
						taxpayerRegistration &&
						this.validateTaxId(taxpayerRegistration)
					) {
						this.taxpayerRegistrationInput?.nativeElement?.setStatusWithMessage(
							"none",
							""
						);
					}
				}
			});
	}

	private validateTaxId(taxpayerRegistration: string): boolean {
		return (
			taxpayerRegistration.length === 11 ||
			taxpayerRegistration.length === 14
		);
	}

	private changeCustomerDataFieldsBySingleOrLinkedReceivableType(): void {
		const linkedItem = Number(this.bankTypeForm?.controls.linkedItem.value);

		if (linkedItem) {
			this.isLinkedType = true;
			this.getCompanyByLinkedItemPayerAndDisableMainFields(linkedItem);
			return;
		}

		this.isLinkedType = false;
		this.selectedLinkedItem = null;
		this.form.controls.companyTaxpayerRegistration.enable();
		this.form.controls.legalName.enable();
	}

	private getCompanyByLinkedItemPayerAndDisableMainFields(
		linkedItem: number
	): void {
		if (this.userReturnedToThisStepWhitoutChangeLinkedItem(linkedItem)) {
			return;
		}

		if (this.form.controls.idCustomer.value) {
			this.customerService
				.getById(this.form.controls.idCustomer.value)
				.pipe(takeUntil(this._onDestroy))
				.subscribe((company) => {
					this.patchFormValues(company);
					this.getPreviousNotificationContent(company.id);
					this.selectedLinkedItem = linkedItem;
					this.form.controls.legalName.disable();
					if (
						this.form.controls.companyTaxpayerRegistration.value &&
						this.validateTaxId(
							this.form.controls.companyTaxpayerRegistration.value
						)
					) {
						this.form.controls.companyTaxpayerRegistration.disable();
					}
				});
		}
	}

	private userReturnedToThisStepWhitoutChangeLinkedItem(
		linkedItem: number
	): boolean {
		return this.selectedLinkedItem === linkedItem;
	}
}
