<atlas-form id="receiptDataStepForm" [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			@if (isMigratedAccountingAccounts()) {
				<atlas-col lg="12">
					<atlas-fixed-alert
						[header]="
							'CREATE_RECEIPT.LABEL.NEW_CHART_ACCOUNT_HEADER'
								| translate
						"
					>
						<span
							[innerHTML]="
								'CREATE_RECEIPT.LABEL.NEW_CHART_ACCOUNT_LABEL'
									| translate
							"
						></span>
					</atlas-fixed-alert>
				</atlas-col>
			}
			<atlas-col lg="7">
				<atlas-section
					[header]="
						'CREATE_RECEIPT.RECEIPT_DATA_STEP.WHAT_TO_CHARGE'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="6">
								<atlas-money
									appAtlasFormElement
									formControlName="totalValue"
									min-value="5,00"
									data-testid="receipt-wizard-asaas-receipt-total-value"
									[minValueErrorMessage]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.MESSAGE.MIN_VALUE'
											| translate
									"
									[attr.max-value]="
										this.bankTypeForm!.controls
											.linkedToggleSelected.value
											? maxValueWithLinkedItem()
											: maxValue
									"
									[maxValueErrorMessage]="
										maxValueErrorInLinkedOption()
									"
									[required]="true"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_VALUE'
											| translate
									"
								></atlas-money>
							</atlas-col>
						</atlas-row>
						<atlas-row>
							<atlas-col lg="11">
								<atlas-textarea
									appAtlasFormElement
									formControlName="observations"
									maxlength="500"
									data-testid="receipt-wizard-asaas-receipt-observations"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_DESCRIPTION'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.PLACEHOLDER.RECEIPT_DESCRIPTION'
											| translate
									"
								></atlas-textarea>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
				<atlas-section
					[header]="
						'CREATE_RECEIPT.RECEIPT_DATA_STEP.HOW_CUSTOMER_PAY'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="6">
								<atlas-select
									appAtlasFormElement
									formControlName="numberInstallments"
									data-testid="receipt-wizard-asaas-receipt-number-installments"
									[default-value]="
										defaultInstallmentsOptionsAsaas()
											?.installmentCount
									"
									[value]="
										defaultInstallmentsOptionsAsaas()
											?.installmentCount
									"
									[atlas-select-change]="
										onChangeSelectInstallments()
									"
									[required]="true"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.INSTALLMENT'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.PLACEHOLDER.INSTALLMENT'
											| translate
									"
									[enableSearch]="true"
								>
									@if (
										paymethodMethodsInstallmentsOptionsAsaas()
									) {
										@for (
											installment of paymethodMethodsInstallmentsOptionsAsaas();
											track installment.valueFromAsaas;
											let i = $index
										) {
											<atlas-option
												[label]="
													paymentMethodInstallmentLabel()[
														i
													]
												"
												[value]="
													installment.installmentCount
												"
											></atlas-option>
										}
									}
								</atlas-select>
							</atlas-col>
							<atlas-col lg="5">
								<atlas-layout gap="2">
									<atlas-date-picker
										appAtlasFormElement
										formControlName="dueDate"
										prevent-past-date="true"
										[required]="true"
										[label]="receiptDueDateLabel"
										data-testid="receipt-wizard-asaas-receipt-due-date"
									></atlas-date-picker>
								</atlas-layout>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
				<atlas-section
					[header]="
						'CREATE_RECEIPT.RECEIPT_DATA_STEP.WHAT_METHOD_OF_RECEIPT'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="11">
								<atlas-layout gap="2">
									<atlas-element-group
										gap="6"
										required-fields="1"
										formArrayName="billingTypeEnum"
									>
										@for (
											billingType of billingTypeEnumForm.controls;
											let i = $index;
											track i
										) {
											<atlas-selection-card
												appAtlasFormElement
												formControlName="{{ i }}"
												type="checkbox"
												checked="true"
												data-testid="receipt-wizard-asaas-receipt-billing-type"
												[value]="
													optionsCheckboxDetails()[i]
														.value
												"
												[label]="
													optionsCheckboxDetails()[i]
														.title
												"
												[subtitle]="
													optionsCheckboxDetails()[i]
														.subtitle
												"
											>
												@for (
													optionsCheckboxDescription of optionsCheckboxDetails()[
														i
													].description;
													track $index
												) {
													<p
														class="selection-card-body-item"
													>
														{{
															optionsCheckboxDescription
														}}
													</p>
												}
											</atlas-selection-card>
										}
									</atlas-element-group>
								</atlas-layout>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
			</atlas-col>
		</atlas-row>
		<app-management-organization-details
			[currentStep]="currentStep"
		></app-management-organization-details>
	</atlas-grid>
</atlas-form>
