import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@core/services/auth/auth.service";
import { LinkedItemsTypeEnum } from "@data/schemas/wizard-receivable/enum/linked-items-type-enum";
import { WizardReceivable } from "@data/schemas/wizard-receivable/wizard-receivable";
import { environment } from "@env";
import { BehaviorSubject, Observable } from "rxjs";
import { LinkedItems } from "@data/schemas/wizard-receivable/linked-items";
import { CreateReceivableResponse } from "@data/schemas/wizard-receivable/create-receivable-response";
import { CustomSegmentService } from "@core/services/custom-segment.service";

@Injectable({
	providedIn: "root"
})
export class WizardReceivableService {
	readonly WIZARD_ENDPOINT = "wizard";
	readonly RECEIVABLE_ENDPOINT = "/receivables";
	readonly SEARCH_ITEMS_TO_LINK_ENDPOINT = "/search-items-to-link";

	private linkedItemDataSubject = new BehaviorSubject<LinkedItems | null>(
		null
	);
	public linkedItemData$ = this.linkedItemDataSubject.asObservable();

	private isWizardOpenedByJsfSubject = new BehaviorSubject<boolean>(false);
	public isWizardOpenedByJsf$ =
		this.isWizardOpenedByJsfSubject.asObservable();

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private segment: CustomSegmentService
	) {}

	createReceivable(
		receivableData: WizardReceivable
	): Observable<{ data: CreateReceivableResponse[] }> {
		return this.http.post<{ data: CreateReceivableResponse[] }>(
			`${environment.API_BFF}/${this.WIZARD_ENDPOINT}${this.RECEIVABLE_ENDPOINT}?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			receivableData,
			{
				withCredentials: true
			}
		);
	}

	getLinkedItemsByType(type: LinkedItemsTypeEnum) {
		return `${environment.API_BFF}/${this.WIZARD_ENDPOINT}${this.RECEIVABLE_ENDPOINT}/search-items-to-link?hashedFingerprint=${this.authService.getHashedFingerprint()}&authorization=${this.authService.getToken()}&type=${type ? type : ""}`;
	}

	setLinkedItemData(data: LinkedItems | null) {
		this.linkedItemDataSubject.next(data);
	}

	getLinkedItemTypeByEnum(linkedItem: LinkedItems): string {
		const typeMapping = {
			[LinkedItemsTypeEnum.INVOICE_NFE]: "invoice",
			[LinkedItemsTypeEnum.INVOICE_RENTAL]: "invoiceRental",
			[LinkedItemsTypeEnum.INVOICE_NFSE]: "serviceInvoice",
			[LinkedItemsTypeEnum.SERVICE_ORDER]: "serviceOrder",
			[LinkedItemsTypeEnum.SALES_ORDER]: "salesOrder"
		};

		return linkedItem.type ? typeMapping[linkedItem.type] : "";
	}

	emitIsWizardOpenedByJsf(value: boolean): void {
		this.isWizardOpenedByJsfSubject.next(value);
	}

	private mappingOpenWizardToEventTracking(openedOn: string): string {
		const eventMapping: { [key: string]: string } = {
			[LinkedItemsTypeEnum.INVOICE_NFE]: "nfe",
			[LinkedItemsTypeEnum.INVOICE_RENTAL]: "rental_invoice",
			[LinkedItemsTypeEnum.INVOICE_NFSE]: "nfse",
			[LinkedItemsTypeEnum.SERVICE_ORDER]: "service_order",
			[LinkedItemsTypeEnum.SALES_ORDER]: "sales_order",
			financial_management: "financial_management",
			sidebar_menu: "sidebar_menu"
		};

		return eventMapping[openedOn] ?? openedOn;
	}

	trackWizardOpenedOn(
		openedOn: string = "",
		interactionId: string = "",
		screen: string = "sidebar",
		properties?: { [k: string]: unknown }
	): void {
		const event = this.mappingOpenWizardToEventTracking(openedOn);
		this.segment.track({
			table: "track_accounts_receivable",
			screen,
			action: `base_erp_accounts_receivable_${event}`,
			properties: {
				...(properties || {}),
				interaction_id: interactionId
			}
		});
	}

	trackWizardStep(
		step: string,
		interactionId: string = "",
		properties?: { [k: string]: unknown }
	): void {
		const _step = this.camelToSnakeCase(step);

		this.segment.track({
			table: "track_accounts_receivable",
			screen: "wizard",
			action: `track_accounts_receivable_wizard_step_${_step}`,
			properties: {
				...(properties || {}),
				interaction_id: interactionId
			}
		});
	}

	private camelToSnakeCase(str: string): string {
		return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
	}
}
