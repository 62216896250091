import { Injectable } from "@angular/core";
import { Bank } from "@data/schemas/bank";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class BankService {
	constructor(private baseCrudBff: BaseCrudBffService) {}

	getAllBanks(
		notAsaas = false,
		searchText = "",
		orderBy = "",
		notBaseBank = false,
		bankInstitution = ""
	): Observable<Bank[]> {
		return this.baseCrudBff.getAll<Bank[]>({
			path: "banks",
			params: {
				notAsaas,
				q: searchText,
				orderBy: orderBy,
				notBaseBank: notBaseBank,
				bankInstitution: bankInstitution
			}
		});
	}
}
