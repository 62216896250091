import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { ignoreHttpError } from "@core/interceptors/http-error.interceptor";
import { AuthService } from "@core/services/auth/auth.service";
import { Notification } from "@data/schemas/notification/notification";
import { NotificationFees } from "@data/schemas/notification/notification-fees";
import { environment } from "@env";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class NotificationService {
	readonly NOTIFICATIONS_ENDPOINT = "notifications";
	readonly GET_NOTIFICATIONS_BY_COMPANY_ENDPOINT =
		"/get-all-notifications-by-company/";
	readonly UPDATE_NOTIFICATIONS_BY_COMPANY_ENDPOINT =
		"/update-all-notifications-by-company/";
	readonly GET_NOTIFICATION_FEES_FROM_ASAAS_ENDPOINT = "/fees";

	private notificationSource = new BehaviorSubject<Notification | undefined>(
		undefined
	);
	currentNotification$ = this.notificationSource.asObservable();

	constructor(
		private baseCrudBff: BaseCrudBffService,
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	changeNotification(notification: Notification | undefined): void {
		this.notificationSource.next(notification);
		return;
	}

	getAllNotificationsByCompany(companyId: number): Observable<Notification> {
		return this.baseCrudBff.getAll<Notification>({
			path: `${this.NOTIFICATIONS_ENDPOINT + this.GET_NOTIFICATIONS_BY_COMPANY_ENDPOINT}${companyId}`
		});
	}

	getNotificationFeesFromAsaas(): Observable<NotificationFees> {
		return this.http.get<NotificationFees>(
			`${environment.API_BFF}/${this.NOTIFICATIONS_ENDPOINT}${
				this.GET_NOTIFICATION_FEES_FROM_ASAAS_ENDPOINT
			}/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true,
				context: ignoreHttpError()
			}
		);
	}
}
