import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
	signal
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	ValidationErrors,
	ValidatorFn,
	Validators
} from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
	BankAndReceiptTypeDataFormGroup,
	CustomerDataFormGroup,
	ReceiptDataFormGroup
} from "./types/form";
import { WizardReceivable } from "@data/schemas/wizard-receivable/wizard-receivable";
import { formatDateFromAtlasDatePicker } from "@shared/utils/date-util";
import { WizardReceivableService } from "@data/services/wizard-receivable.service";
import { DecimalTransformer } from "@shared/utils/forms/decimal-transformer";
import { AlertsService } from "@shared/components/atlas/alerts/services/alerts.service";
import { GLOBAL_ALERT_KEY } from "@shared/components/atlas/alerts/types";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { formatCompanyTaxpayerRegistration } from "@shared/utils/string-util";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { Subject, takeUntil } from "rxjs";
import { BanksNameEnum } from "@enums/banks-name.enum";
import { PostMessageService } from "@shared/services/post-message.service";
import { LinkedItemsTypeEnum } from "@data/schemas/wizard-receivable/enum/linked-items-type-enum";
import { AtlasWizardChangeStep } from "@shared/types/atlas/atlas-wizard-change-step";
import { CreateReceivableResponse } from "@data/schemas/wizard-receivable/create-receivable-response";
import { Steps } from "./types/steps.enum";
import { v4 as uuidv4 } from "uuid";

@Component({
	selector: "app-create-new-receipt-wizard",
	templateUrl: "./create-new-receipt-wizard.component.html",
	styleUrl: "./create-new-receipt-wizard.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class CreateNewReceiptWizardComponent implements OnInit, OnDestroy {
	private readonly bsModalRef = inject(BsModalRef);
	private fb = inject(FormBuilder);
	public interactionId: string = uuidv4();

	protected loading = false;
	protected isWizardOpenedByJsf = false;
	protected currentStep: string = Steps.BANK_AND_RECEIPT_TYPE_DATA;

	steps = Steps;

	private _onDestroy = new Subject<boolean>();
	isBankAsaas = signal<boolean>(false);

	constructor(
		private wizardReceivableService: WizardReceivableService,
		private alertsService: AlertsService,
		private automataTranslateService: AutomataTranslateService,
		private cdr: ChangeDetectorRef,
		private segment: CustomSegmentService,
		private postMessageService: PostMessageService
	) {}

	ngOnInit(): void {
		this.wizardReceivableService.isWizardOpenedByJsf$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((isWizardOpenedByJsf) => {
				this.isWizardOpenedByJsf = isWizardOpenedByJsf;
			});
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this._onDestroy.complete();
	}

	protected closeWizard(): void {
		this.bsModalRef.hide();
		this.trackWizard("close");
		this.cleanWizardLinkedItemData();
	}

	protected receiptForm = this.fb.group({
		bankAndReceiptTypeData: this.fb.group<BankAndReceiptTypeDataFormGroup>({
			bank: new FormControl<number>(
				{ value: 0, disabled: false },
				{
					validators: [Validators.required]
				}
			),
			bankName: new FormControl<string>(BanksNameEnum.ASAAS),
			linkedToggleSelected: new FormControl<boolean>(true),
			linkedItem: new FormControl<number>(0),
			linkedType: new FormControl<string>(""),
			linkedItemValue: new FormControl<number>(0),
			documentNumber: new FormControl<string | null>(null)
		}),
		receiptData: this.fb.group<ReceiptDataFormGroup>({
			totalValue: new FormControl<number>(0, {
				validators: [Validators.required]
			}),
			valueToReceive: new FormControl<number>(0, {
				validators: [Validators.required]
			}),
			bankSlipPixNetValue: new FormControl<number>(0),
			creditCardNetValue: new FormControl<number>(0),
			bankSlipPopoverContent: new FormControl<string>(""),
			creditCardPopoverContent: new FormControl<string>(""),
			observations: new FormControl<string>(""),
			numberInstallments: new FormControl<number>(0, {
				validators: [Validators.required]
			}),
			duplicateType: new FormControl<string>(""),
			dueDate: new FormControl<string>("", {
				validators: [Validators.required]
			}),
			billingTypeEnum: new FormArray<FormControl<boolean>>([], {
				validators: [Validators.required]
			}),
			paymentMethod: new FormControl<number>(0, {
				validators: [Validators.required]
			}),
			paymentMethodName: new FormControl<string>(""),
			paymentMethodTerm: new FormControl<number>(0, {
				validators: [Validators.required]
			}),
			paymentMethodTermName: new FormControl<string>(""),
			accountingAccount: new FormControl<number>(0, {
				validators: [Validators.required]
			}),
			accountingAccountName: new FormControl<string>(""),
			costCenter: new FormControl<number>(0),
			costCenterName: new FormControl<string>(""),
			project: new FormControl<number>(0),
			projectName: new FormControl<string>("")
		}),
		customerData: this.fb.group<CustomerDataFormGroup>({
			idCustomer: new FormControl<number>(0, Validators.required),
			legalName: new FormControl<string>(""),
			companyTaxpayerRegistration: new FormControl<string>(
				"",
				Validators.required
			),
			email: new FormControl<string>(
				"",
				this.emailNotificationEnabledWithoutFieldFilledIn()
			),
			phone: new FormControl<string>(""),
			companyName: new FormControl<string>(""),
			cellphone: new FormControl<string>(
				"",
				this.smsWhatsappNotificationEnabledWithoutFieldFilledIn()
			),
			postalCode: new FormControl<string>(""),
			city: new FormControl<string>({ value: "", disabled: true }),
			neighborhood: new FormControl<string>(""),
			address: new FormControl<string>(""),
			addressNumber: new FormControl<string>(""),
			complement: new FormControl<string>(""),
			uf: new FormControl<string>(""),
			ibgeCode: new FormControl<string>(""),
			whatsappNotification: new FormControl<boolean>(
				false,
				this.smsWhatsappNotificationEnabledWithoutFieldFilledIn()
			),
			emailNotification: new FormControl<boolean>(
				false,
				this.emailNotificationEnabledWithoutFieldFilledIn()
			),
			smsNotification: new FormControl<boolean>(
				false,
				this.smsWhatsappNotificationEnabledWithoutFieldFilledIn()
			),
			emailSmsNotificationFee: new FormControl<number | null>(null),
			whatsappNotificationFee: new FormControl<number | null>(null),
			selectedToggle: new FormControl<string>("registeredCustomer")
		})
	});

	protected submitWizard(event: Event): void {
		this.trackWizard("save");
		event.preventDefault();
		this.loading = true;
		this.wizardReceivableService
			.createReceivable(this.createWizardReceivableObject())
			.subscribe({
				next: (response: { data: CreateReceivableResponse[] }) => {
					this.loading = false;
					const accounts_receivable_id = response?.data?.[0]?.id;
					this.trackWizard("success", { accounts_receivable_id });
					this.alertsService.success({
						key: GLOBAL_ALERT_KEY,
						message:
							this.automataTranslateService.getTranslationSync(
								"CREATE_RECEIPT.LABEL.SUCCESS_ALERT"
							)
					});
					this.bsModalRef.hide();

					this.postMessageService.sendMessageToIframe({
						intention: "refreshReceiptList",
						payload: null
					});

					if (this.isWizardOpenedByJsf) {
						this.postMessageService.sendMessageToIframe({
							intention: "linkedItemAutoSave",
							payload: {
								type: this.linkedTypeEnumValue
							}
						});
					}

					this.cleanWizardLinkedItemData();
				},
				error: () => {
					this.trackWizard("fail");
					this.loading = false;
					this.cdr.detectChanges();
				}
			});
	}

	protected setStep(event: Event): void {
		const detail = (event as AtlasWizardChangeStep)?.detail;
		this.currentStep = detail?.step?.name;

		this.wizardReceivableService.trackWizardStep(
			this.currentStep,
			this.interactionId
		);

		if (this.currentStep === Steps.RECEIPT_DATA.toString()) {
			this.isBankAsaas.set(
				this.receiptForm.controls.bankAndReceiptTypeData.controls
					.bankName.value === BanksNameEnum.ASAAS.toString()
			);
		}
	}

	private cleanWizardLinkedItemData(): void {
		this.wizardReceivableService.setLinkedItemData(null);
		this.wizardReceivableService.emitIsWizardOpenedByJsf(false);
		this.isWizardOpenedByJsf = false;
	}

	private createWizardReceivableObject(): WizardReceivable {
		const wizardReceivable: WizardReceivable = {
			receiptData: {
				bank: {
					id:
						this.receiptForm.controls.bankAndReceiptTypeData
							.controls.bank.value ?? 0
				},
				value: DecimalTransformer(
					this.receiptForm.controls.receiptData.controls
						.valueToReceive.value ?? 0
				),
				totalValue: DecimalTransformer(
					this.receiptForm.controls.receiptData.value.totalValue
				),
				bankSlipPixNetValue: DecimalTransformer(
					this.receiptForm.controls.receiptData.controls
						.bankSlipPixNetValue.value ?? 0
				),
				creditCardNetValue: DecimalTransformer(
					this.receiptForm.controls.receiptData.controls
						.creditCardNetValue.value ?? 0
				),
				observations:
					this.receiptForm.controls.receiptData.controls.observations
						.value ?? "",
				numberInstallments:
					this.receiptForm.controls.receiptData.controls
						.numberInstallments.value ?? 0,
				duplicateType: {
					id:
						this.receiptForm.controls.receiptData.controls
							.duplicateType.value ?? ""
				},
				dueDate: formatDateFromAtlasDatePicker(
					this.receiptForm.controls.receiptData.controls.dueDate
						.value || ""
				),
				billingType: this.getBillingType(),
				paymentMethodTerm: {
					id:
						this.receiptForm.controls.receiptData.controls
							.paymentMethodTerm.value ?? 0
				},
				accountingAccount: {
					id:
						this.receiptForm.controls.receiptData.controls
							.accountingAccount.value ?? 0
				},
				costCenter: {
					id:
						this.receiptForm.controls.receiptData.controls
							.costCenter.value ?? 0
				},
				project: {
					id:
						this.receiptForm.controls.receiptData.controls.project
							.value ?? 0
				},
				linkedItemId:
					this.receiptForm.controls.bankAndReceiptTypeData.controls
						.linkedItem.value ?? null,
				linkedItemType: this.linkedTypeEnumValue ?? null
			},
			customerData: {
				customer: {
					id:
						this.receiptForm.controls.customerData.controls
							.idCustomer.value ?? 0
				},
				legalName:
					this.receiptForm.controls.customerData.controls.legalName
						.value ?? "",
				companyTaxpayerRegistration: formatCompanyTaxpayerRegistration(
					this.receiptForm.controls.customerData.controls
						.companyTaxpayerRegistration.value ?? ""
				),
				email:
					this.receiptForm.controls.customerData.controls.email
						.value ?? "",
				phone1: this.getDddAndPhoneNumber(
					this.receiptForm.controls.customerData.controls.phone
						.value ?? ""
				).phoneNumber,
				areaCode1: this.getDddAndPhoneNumber(
					this.receiptForm.controls.customerData.controls.phone
						.value ?? ""
				).ddd,
				phoneType1: {
					id: "COMERCIAL"
				},
				companyName:
					this.receiptForm.controls.customerData.controls.companyName
						.value ?? "",
				phone2: this.getDddAndPhoneNumber(
					this.receiptForm.controls.customerData.controls.cellphone
						.value ?? ""
				).phoneNumber,
				areaCode2: this.getDddAndPhoneNumber(
					this.receiptForm.controls.customerData.controls.cellphone
						.value ?? ""
				).ddd,
				phoneType2: {
					id: "CELULAR"
				},
				postalCode: this.formatPostalCode(
					this.receiptForm.controls.customerData.controls.postalCode
						.value ?? ""
				),
				city:
					this.receiptForm.controls.customerData.controls.city
						.value ?? "",
				neighborhood:
					this.receiptForm.controls.customerData.controls.neighborhood
						.value ?? "",
				address:
					this.receiptForm.controls.customerData.controls.address
						.value ?? "",
				addressNumber:
					this.receiptForm.controls.customerData.controls
						.addressNumber.value ?? "",
				complement:
					this.receiptForm.controls.customerData.controls.complement
						.value ?? "",
				uf:
					this.receiptForm.controls.customerData.controls.uf.value ??
					"",
				ibge:
					this.receiptForm.controls.customerData.controls.ibgeCode
						.value ?? "",
				whatsappNotification: this.isBankAsaas()
					? this.receiptForm.controls.customerData.controls
							.whatsappNotification.value
					: null,
				emailNotification: this.isBankAsaas()
					? this.receiptForm.controls.customerData.controls
							.emailNotification.value
					: null,
				smsNotification: this.isBankAsaas()
					? this.receiptForm.controls.customerData.controls
							.smsNotification.value
					: null
			}
		};

		return wizardReceivable;
	}

	private emailNotificationEnabledWithoutFieldFilledIn(): ValidatorFn {
		return (): ValidationErrors | null => {
			if (
				!this.receiptForm ||
				this.receiptForm.controls.bankAndReceiptTypeData.controls
					.bankName.value !== BanksNameEnum.ASAAS.toString()
			) {
				return null;
			}

			const emailValue = String(
				this.receiptForm.controls.customerData.controls.email.value
			);

			const emailNotificationValue = Boolean(
				this.receiptForm.controls.customerData.controls
					.emailNotification.value
			);

			if (!emailValue && emailNotificationValue) {
				return { emailNotificationEnabledWithoutFieldFilledIn: true };
			}

			return null;
		};
	}

	private smsWhatsappNotificationEnabledWithoutFieldFilledIn(): ValidatorFn {
		return (): ValidationErrors | null => {
			if (
				!this.receiptForm ||
				this.receiptForm.controls.bankAndReceiptTypeData.controls
					.bankName.value !== BanksNameEnum.ASAAS.toString()
			) {
				return null;
			}

			const cellphoneValue = String(
				this.receiptForm.controls.customerData.controls.cellphone.value
			);

			const smsNotificationValue = Boolean(
				this.receiptForm.controls.customerData.controls.smsNotification
					.value
			);

			const whatsappNotificationValue = Boolean(
				this.receiptForm.controls.customerData.controls
					.whatsappNotification.value
			);

			if (
				!cellphoneValue &&
				smsNotificationValue &&
				whatsappNotificationValue
			) {
				return {
					smsAndWhatsappNotificationsEnabledWithoutFieldFilledIn: true
				};
			}

			if (!cellphoneValue && smsNotificationValue) {
				return {
					smsNotificationEnabledWithoutFieldFilledIn: true
				};
			}

			if (!cellphoneValue && whatsappNotificationValue) {
				return {
					whatsappNotificationEnabledWithoutFieldFilledIn: true
				};
			}

			return null;
		};
	}

	private formatBillingTypeEnum(
		billingTypeEnum: FormArray<FormControl<boolean>>
	): string {
		if (billingTypeEnum.value[0] && !billingTypeEnum.value[1]) {
			return "BOLETO";
		}

		if (!billingTypeEnum.value[0] && billingTypeEnum.value[1]) {
			return "CREDIT_CARD";
		}

		if (billingTypeEnum.value[0] && billingTypeEnum.value[1]) {
			return "UNDEFINED";
		}

		return "";
	}

	private getBillingType(): { id: string } | null {
		if (!this.isBankAsaas()) {
			return null;
		}

		return {
			id: this.formatBillingTypeEnum(
				this.receiptForm.controls.receiptData.controls.billingTypeEnum
			)
		};
	}

	private getDddAndPhoneNumber(phone: string): {
		ddd: string;
		phoneNumber: string;
	} {
		if (!phone) {
			return { ddd: "", phoneNumber: "" };
		}

		const phoneRegex = /^\d+$/;
		if (phoneRegex.test(phone)) {
			const ddd = phone.slice(0, 2);
			const phoneNumber = phone.slice(2);
			return { ddd, phoneNumber };
		}

		const ddd = phone.split(" ")[0].replace("(", "").replace(")", "");
		const phoneNumber = phone
			.split(" ")[1]
			.replace("-", "")
			.replace(" ", "");
		return { ddd, phoneNumber };
	}

	private formatPostalCode(postalCode: string): string {
		if (!postalCode) {
			return "";
		}
		return postalCode.replace("-", "");
	}

	private trackWizard(
		event: "save" | "close" | "success" | "fail",
		properties?: { [k: string]: unknown }
	): void {
		this.segment.track({
			table: "track_accounts_receivable",
			screen: "sidebar",
			action: `base_erp_accounts_receivable_${event}`,
			properties: {
				...(properties || {}),
				interaction_id: this.interactionId
			}
		});
	}

	private get linkedTypeEnumValue(): string {
		const typeMapping = {
			invoiceRental: LinkedItemsTypeEnum.INVOICE_RENTAL,
			salesOrder: LinkedItemsTypeEnum.SALES_ORDER,
			serviceOrder: LinkedItemsTypeEnum.SERVICE_ORDER,
			invoice: LinkedItemsTypeEnum.INVOICE_NFE,
			serviceInvoice: LinkedItemsTypeEnum.INVOICE_NFSE
		};

		const linkedTypeValue = this.receiptForm.controls.bankAndReceiptTypeData
			.controls.linkedType.value as keyof typeof typeMapping;

		return typeMapping[linkedTypeValue] || "";
	}
}
