import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CityData } from "@data/schemas/city-data";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CityService {
	constructor(private readonly http: HttpClient) {}

	getCities(): Observable<CityData[]> {
		return this.http.get<CityData[]>(
			"https://servicodados.ibge.gov.br/api/v1/localidades/distritos?orderBy=nome"
		);
	}
}
