import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CepData } from "@data/schemas/cep-data";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CepService {
	constructor(private readonly http: HttpClient) {}

	getCepData(cep: string): Observable<CepData> {
		return this.http.get<CepData>(`https://viacep.com.br/ws/${cep}/json/`);
	}
}
