import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ignoreHttpError } from "@core/interceptors/http-error.interceptor";
import { AuthService } from "@core/services/auth/auth.service";
import { MigratedAccountingAccounts } from "@data/schemas/persistence-unit/migrated-accounting-accounts";
import { PersistenceUnit } from "@data/schemas/persistence-unit/persistence-unit";
import { environment } from "@env";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class PersistenceUnitService {
	readonly PERSISTENCE_UNIT_ENDPOINT = "persistence-unit";
	readonly INTEGRATION_STATUS_ENDPOINT = "/integrations-status";

	constructor(
		private http: HttpClient,
		private authService: AuthService
	) {}

	integrationStatus(): Observable<PersistenceUnit> {
		return this.http.get<PersistenceUnit>(
			`${environment.API_BFF}/${this.PERSISTENCE_UNIT_ENDPOINT}${
				this.INTEGRATION_STATUS_ENDPOINT
			}/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true,
				context: ignoreHttpError()
			}
		);
	}

	getMigratedAccountingAccounts(): Observable<MigratedAccountingAccounts> {
		return this.http.get<MigratedAccountingAccounts>(
			`${this.PERSISTENCE_UNIT_ENDPOINT}/migrated-accounting-accounts`,
			{
				withCredentials: true
			}
		);
	}
}
