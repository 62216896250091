<atlas-form id="bankAndReceiptTypeDataForm" [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			<atlas-col lg="12">
				<atlas-section
					[header]="
						'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.HEADER'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-layout gap="2">
									<atlas-toggle
										default-value="linked"
										required="true"
										[disabled]="blockWizardToLinkedItem"
									>
										<atlas-toggle-item
											(atlas-toggle-item-change)="
												linkedReceiptToggleChecked(
													$event
												)
											"
											[checked]="true"
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LINKED'
													| translate
											"
											value="linked"
											data-testid="receipt-wizard-bank-linked"
										></atlas-toggle-item>
										<atlas-toggle-item
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.SINGLE'
													| translate
											"
											value="single"
											data-testid="receipt-wizard-bank-single"
										></atlas-toggle-item>
									</atlas-toggle>
								</atlas-layout>
							</atlas-col>
						</atlas-row>
						@if (linkedToggleSelected) {
							<atlas-row>
								<atlas-col lg="12">
									<atlas-layout gap="2">
										<atlas-text muted="true">
											{{
												"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LINKED_DESCRIPTION"
													| translate
											}}
										</atlas-text>
									</atlas-layout>
								</atlas-col>
							</atlas-row>

							<atlas-row>
								<atlas-col lg="4">
									<atlas-select
										#atlasSelectLinkedItemType
										appAtlasFormElement
										formControlName="linkedType"
										data-testid="receipt-wizard-bank-linked-type"
										[required]="true"
										[label]="
											'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.LINKED_ITEM_TYPE'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.PLACEHOLDER.LINKED_ITEM_TYPE'
												| translate
										"
										(atlas-select-change)="
											setLinkedItemType()
										"
									>
										<atlas-option
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.SALES_ORDER'
													| translate
											"
											value="salesOrder"
										>
										</atlas-option>
										<atlas-option
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.SERVICE_ORDER'
													| translate
											"
											value="serviceOrder"
										></atlas-option>
										<atlas-option
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.INVOICE'
													| translate
											"
											value="invoice"
										></atlas-option>
										<atlas-option
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.SERVICE_INVOICE'
													| translate
											"
											value="serviceInvoice"
										></atlas-option>
										<atlas-option
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.INVOICE_RENTAL'
													| translate
											"
											value="invoiceRental"
										></atlas-option
									></atlas-select>
								</atlas-col>
							</atlas-row>

							<atlas-row>
								<atlas-col lg="4">
									@if (blockWizardToLinkedItem) {
										<atlas-input
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.LINKED_ITEM'
													| translate
											"
											[placeholder]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.PLACEHOLDER.LINKED_ITEM'
													| translate
											"
											[value]="
												'Nº ' +
												this.form.controls
													.documentNumber.value
											"
											[disabled]="true"
											data-testid="receipt-wizard-bank-and-receipt-linked-item"
										></atlas-input>
									} @else {
										<atlas-select
											#atlasSelectLinkedItem
											appAtlasFormElement
											formControlName="linkedItem"
											data-testid="receipt-wizard-bank-linked-item"
											[required]="true"
											[label]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.LABEL.LINKED_ITEM'
													| translate
											"
											[placeholder]="
												'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.PLACEHOLDER.LINKED_ITEM'
													| translate
											"
											value-key="id"
											label-key="labeledDocumentNumber"
											extra-keys="labeledCompany;labeledTaxpayerRegistration;labeledValue"
											search-params="searchTerm"
											[enableSearch]="true"
											[searchUrl]="url"
											(atlas-select-change)="
												getSelectedLinkedItem()
											"
										></atlas-select>
									}
								</atlas-col>
							</atlas-row>
						} @else {
							<atlas-row>
								<atlas-col lg="12">
									<atlas-layout gap="2">
										<atlas-text muted="true">
											{{
												"CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.SINGLE_DESCRIPTION"
													| translate
											}}
										</atlas-text>
									</atlas-layout>
								</atlas-col>
							</atlas-row>
						}
					</atlas-grid>
				</atlas-section>

				<atlas-section
					[header]="
						'CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_HEADING'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-layout gap="2">
									<atlas-select
										#atlasSelectReceiptBank
										appAtlasFormElement
										formControlName="bank"
										data-testid="receipt-wizard-bank-select-bank"
										[required]="true"
										[label]="
											'CREATE_RECEIPT.RECEIPT_DATA_STEP.BANK_SELECT'
												| translate
										"
										[enableSearch]="true"
										value-key="idBank"
										label-key="bank"
										[placeholder]="
											'CREATE_RECEIPT.BANK_AND_RECEIPT_TYPE_DATA_STEP.BANK_SELECT'
												| translate
										"
										(atlas-select-change)="
											setSelectedBankName()
										"
									>
										@if (receiptBank$ | async; as banks) {
											@for (
												bank of banks;
												track bank.id
											) {
												<atlas-option
													[label]="bank.banco"
													[value]="bank.id"
												></atlas-option>
											}
										}
									</atlas-select>
								</atlas-layout>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
			</atlas-col>
		</atlas-row>
	</atlas-grid>
</atlas-form>
