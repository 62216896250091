<atlas-form id="customerDataStepForm" [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			<atlas-col lg="12">
				<atlas-section
					[header]="
						'CREATE_RECEIPT.CUSTOMER_DATA_STEP.WHO_WILL_YOU_CHARGE'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-layout gap="2">
									<atlas-toggle
										default-value="registeredCustomer"
										[value]="selectedToggle"
										(atlas-toggle-item-check)="
											resetEmailAndCellphoneControls()
										"
										[disabled]="isLinkedType"
									>
										<atlas-toggle-item
											(atlas-toggle-item-change)="
												registeredCustomerToggleChecked(
													$event
												)
											"
											[checked]="true"
											[label]="
												'CREATE_RECEIPT.CUSTOMER_DATA_STEP.REGISTERED_CUSTOMER_TOGGLE'
													| translate
											"
											value="registeredCustomer"
											data-testid="receipt-wizard-customer-registered-customer"
										></atlas-toggle-item>
										<atlas-toggle-item
											[label]="
												'CREATE_RECEIPT.CUSTOMER_DATA_STEP.NEW_CUSTOMER_TOGGLE'
													| translate
											"
											value="newCustomer"
											data-testid="receipt-wizard-customer-new-customer"
										></atlas-toggle-item>
									</atlas-toggle>
								</atlas-layout>
							</atlas-col>
						</atlas-row>

						<atlas-row>
							<atlas-col lg="6">
								@if (isLinkedType) {
									<atlas-input
										appAtlasFormElement
										formControlName="legalName"
										data-testid="receipt-wizard-customer-legal-name"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.SELECT_CUSTOMER_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.NEW_CUSTOMER_PLACEHOLDER'
												| translate
										"
										[required]="true"
									></atlas-input>
								} @else if (
									selectedToggle === "registeredCustomer"
								) {
									<atlas-select
										#atlasSelectCustomer
										appAtlasFormElement
										formControlName="idCustomer"
										data-testid="receipt-wizard-customer-id-customer"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.SELECT_CUSTOMER_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.SELECT_CUSTOMER_PLACEHOLDER'
												| translate
										"
										[required]="true"
										value-key="id"
										label-key="razaoSocial"
										extra-keys="cnpj;email"
										search-params="searchText"
										(atlas-select-change)="
											getSelectedCustomer()
										"
										[enableSearch]="true"
										[searchUrl]="searchCustomersUrl"
									>
									</atlas-select>
								} @else {
									<atlas-input
										appAtlasFormElement
										formControlName="legalName"
										data-testid="receipt-wizard-customer-legal-name"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.SELECT_CUSTOMER_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.NEW_CUSTOMER_PLACEHOLDER'
												| translate
										"
										[required]="true"
									></atlas-input>
								}
							</atlas-col>
						</atlas-row>
						@if (
							(this.form.controls.idCustomer.value &&
								!loadingSelectedCustomerData) ||
							selectedToggle === "newCustomer" ||
							isLinkedType
						) {
							<atlas-row>
								<atlas-col lg="4">
									<atlas-masked-input
										#taxpayerRegistrationInput
										appAtlasFormElement
										formControlName="companyTaxpayerRegistration"
										data-testid="receipt-wizard-customer-company-taxpayer-registration"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.TAX_PAYER_REGISTRATION'
												| translate
										"
										mask-alias="cpf-cnpj"
										[isCustomValidation]="true"
										[required]="true"
										[value]="
											form.controls
												.companyTaxpayerRegistration
												.value
										"
									></atlas-masked-input>
								</atlas-col>
								<atlas-col lg="4">
									<atlas-masked-input
										#emailInput
										appAtlasFormElement
										formControlName="email"
										data-testid="receipt-wizard-customer-email"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.EMAIL'
												| translate
										"
										mask-alias="email"
										placeholder="Informe um e-mail de contato"
										[value]="form.controls.email.value"
										[isCustomValidation]="true"
									></atlas-masked-input>
								</atlas-col>
								<atlas-col lg="4">
									<atlas-masked-input
										#cellphoneInput
										appAtlasFormElement
										formControlName="cellphone"
										data-testid="receipt-wizard-customer-cell-phone"
										mask-alias="cell-phone"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.CELLPHONE_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.CELLPHONE_PLACEHOLDER'
												| translate
										"
										[value]="form.controls.cellphone.value"
										[isCustomValidation]="true"
									></atlas-masked-input>
								</atlas-col>
							</atlas-row>
						}
					</atlas-grid>
				</atlas-section>

				@if (
					(this.form.controls.idCustomer.value &&
						!loadingSelectedCustomerData) ||
					selectedToggle === "newCustomer" ||
					isLinkedType
				) {
					<atlas-section
						[header]="
							'CREATE_RECEIPT.CUSTOMER_DATA_STEP.MORE_INFORMATIONS_HEADER'
								| translate
						"
						[collapsible]="true"
						[optional]="true"
						[collapsed]="true"
					>
						<atlas-grid>
							<atlas-row>
								<atlas-col lg="4">
									<atlas-input
										appAtlasFormElement
										formControlName="companyName"
										data-testid="receipt-wizard-customer-company-name"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.FANTASY_NAME_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.FANTASY_NAME_PLACEHOLDER'
												| translate
										"
										[optional]="true"
										[value]="form.controls.companyName"
									></atlas-input>
								</atlas-col>
								<atlas-col lg="4">
									<atlas-masked-input
										appAtlasFormElement
										formControlName="phone"
										data-testid="receipt-wizard-customer-phone"
										lg="4"
										mask-alias="phone"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.PHONE_LABEL'
												| translate
										"
										[value]="form.controls.phone.value"
									></atlas-masked-input
								></atlas-col>
							</atlas-row>
							<atlas-row>
								<atlas-col lg="4">
									<atlas-postal-code
										appAtlasFormElement
										formControlName="postalCode"
										data-testid="receipt-wizard-customer-postal-code"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.ZIP_CODE'
												| translate
										"
										placeholder="00000-000"
										(atlas-input-blur)="
											getAddressByPostalCode()
										"
										disable-search="true"
										[loading]="loadingPostalCode"
										[value]="form.controls.postalCode.value"
									></atlas-postal-code>
								</atlas-col>
								<atlas-col lg="4">
									@if (!noCityFoundByZipCode) {
										<atlas-input
											appAtlasFormElement
											formControlName="city"
											data-testid="receipt-wizard-customer-city"
											[label]="
												'CREATE_RECEIPT.CUSTOMER_DATA_STEP.CITY_LABEL'
													| translate
											"
											[placeholder]="
												'CREATE_RECEIPT.CUSTOMER_DATA_STEP.CITY_PLACEHOLDER'
													| translate
											"
											[value]="form.controls.city.value"
										></atlas-input>
									} @else {
										<atlas-select
											#atlasSelectCity
											appAtlasFormElement
											formControlName="city"
											data-testid="receipt-wizard-customer-city"
											[label]="
												'CREATE_RECEIPT.CUSTOMER_DATA_STEP.CITY_LABEL'
													| translate
											"
											[placeholder]="
												'CREATE_RECEIPT.CUSTOMER_DATA_STEP.CITY_PLACEHOLDER'
													| translate
											"
											[value]="form.controls.city.value"
											[enableSearch]="true"
											[hideOptional]="true"
											(atlas-select-change)="
												getSelectedCityAndSetUf()
											"
										>
											@for (
												city of cities;
												track city.nome
											) {
												<atlas-select-option
													[value]="city.nome"
													[label]="
														city.nome +
														'-' +
														city.municipio
															.microrregiao
															.mesorregiao.UF
															.sigla
													"
												></atlas-select-option>
											}
										</atlas-select>
									}
								</atlas-col>
								<atlas-col lg="4">
									<atlas-input
										appAtlasFormElement
										formControlName="address"
										data-testid="receipt-wizard-customer-address"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.STREET_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.STREET_PLACEHOLDER'
												| translate
										"
										[value]="form.controls.address.value"
									></atlas-input>
								</atlas-col>
							</atlas-row>
							<atlas-row>
								<atlas-col lg="4">
									<atlas-input
										appAtlasFormElement
										formControlName="neighborhood"
										data-testid="receipt-wizard-customer-neighborhood"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.DISTRICT_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.DISTRICT_PLACEHOLDER'
												| translate
										"
										[value]="
											form.controls.neighborhood.value
										"
									></atlas-input>
								</atlas-col>
								<atlas-col lg="4">
									<atlas-input
										appAtlasFormElement
										formControlName="addressNumber"
										data-testid="receipt-wizard-customer-address-number"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.ADDRESS_NUMBER_LABEL'
												| translate
										"
										placeholder="0000"
										[value]="
											form.controls.addressNumber.value
										"
										type="string"
									></atlas-input>
								</atlas-col>
								<atlas-col lg="4">
									<atlas-input
										appAtlasFormElement
										formControlName="complement"
										data-testid="receipt-wizard-customer-complement"
										[label]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.ADDRESS_COMPLEMENT_LABEL'
												| translate
										"
										[placeholder]="
											'CREATE_RECEIPT.CUSTOMER_DATA_STEP.ADDRESS_COMPLEMENT_PLACEHOLDER'
												| translate
										"
										[value]="form.controls.complement"
									></atlas-input>
								</atlas-col>
							</atlas-row>
						</atlas-grid>
					</atlas-section>
				}
				@if (
					isBankAsaas &&
					((this.form.controls.idCustomer.value &&
						!loadingSelectedCustomerData) ||
						selectedToggle === "newCustomer" ||
						isLinkedType === true)
				) {
					<atlas-section
						[header]="
							'CREATE_RECEIPT.CUSTOMER_DATA_STEP.NOTIFICATIONS_SECTION_HEADER'
								| translate
						"
					>
						<atlas-text
							slot="description"
							[innerHTML]="
								'CREATE_RECEIPT.CUSTOMER_DATA_STEP.NOTIFICATIONS_SECTION_DESCRIPTION'
									| translate
							"
						></atlas-text>
						<atlas-grid>
							<atlas-row>
								<atlas-col lg="6">
									<div
										class="notification-send-type-container"
									>
										<div
											class="notification-send-type-checkbox-container"
										>
											<atlas-checkbox
												#whatsappNotificationCheckbox
												appAtlasFormElement
												formControlName="whatsappNotification"
												icon="whatsapp"
												name="whatsapp"
												data-testid="receipt-wizard-customer-whatsapp-notification"
												[tooltip]="
													!form.controls
														.whatsappNotification
														.enabled
														? ('CREATE_RECEIPT.CUSTOMER_DATA_STEP.WHATSAPP_TOOLTIP'
															| translate)
														: ''
												"
												[buttonStyle]="true"
												[isCustomValidation]="true"
												(click)="
													handleClick(
														'whatsappNotification'
													)
												"
												(atlas-checkbox-uncheck)="
													showAlertWhenCheckboxIsUnchecked(
														'whatsappNotification'
													)
												"
												>{{
													"CREATE_RECEIPT.CUSTOMER_DATA_STEP.WHATSAPP"
														| translate
												}}</atlas-checkbox
											>
											<atlas-icon-button
												theme="primary"
												icon="info"
												[popoverTitle]="
													'CREATE_RECEIPT.CUSTOMER_DATA_STEP.WHATSAPP'
														| translate
												"
												[popoverContent]="
													whatsAppFeePopoverContent()
												"
											></atlas-icon-button>
										</div>
										<div
											class="notification-send-type-checkbox-container"
										>
											<atlas-checkbox
												#emailNotificationCheckbox
												appAtlasFormElement
												formControlName="emailNotification"
												data-testid="receipt-wizard-customer-email-notification"
												icon="envelope"
												name="email"
												[tooltip]="
													!form.controls
														.emailNotification
														.enabled
														? ('CREATE_RECEIPT.CUSTOMER_DATA_STEP.EMAIL_TOOLTIP'
															| translate)
														: ''
												"
												[buttonStyle]="true"
												[isCustomValidation]="true"
												(click)="
													handleClick(
														'emailNotification'
													)
												"
												(atlas-checkbox-uncheck)="
													showAlertWhenCheckboxIsUnchecked(
														'emailNotification'
													)
												"
												>{{
													"CREATE_RECEIPT.CUSTOMER_DATA_STEP.EMAIL"
														| translate
												}}</atlas-checkbox
											>
											<atlas-icon-button
												theme="primary"
												icon="info"
												[popoverTitle]="
													'CREATE_RECEIPT.CUSTOMER_DATA_STEP.EMAIL'
														| translate
												"
												[popoverContent]="
													messagingFeePopoverContent()
												"
											></atlas-icon-button>
										</div>
										<div
											class="notification-send-type-checkbox-container"
										>
											<atlas-checkbox
												#smsNotificationCheckbox
												appAtlasFormElement
												formControlName="smsNotification"
												data-testid="receipt-wizard-customer-sms-notification"
												icon="smartphone"
												name="sms"
												[tooltip]="
													!form.controls
														.smsNotification.enabled
														? ('CREATE_RECEIPT.CUSTOMER_DATA_STEP.SMS_TOOLTIP'
															| translate)
														: ''
												"
												[buttonStyle]="true"
												[isCustomValidation]="true"
												(click)="
													handleClick(
														'smsNotification'
													)
												"
												(atlas-checkbox-uncheck)="
													showAlertWhenCheckboxIsUnchecked(
														'smsNotification'
													)
												"
												>{{
													"CREATE_RECEIPT.CUSTOMER_DATA_STEP.SHORT_MESSAGE_SERVICE"
														| translate
												}}</atlas-checkbox
											>
											<atlas-icon-button
												theme="primary"
												icon="info"
												[popoverTitle]="
													'CREATE_RECEIPT.CUSTOMER_DATA_STEP.SHORT_MESSAGE_SERVICE'
														| translate
												"
												[popoverContent]="
													messagingFeePopoverContent()
												"
											></atlas-icon-button>
										</div>
									</div>
								</atlas-col>
							</atlas-row>
						</atlas-grid>
					</atlas-section>
				}
			</atlas-col>
		</atlas-row>
	</atlas-grid>
</atlas-form>
