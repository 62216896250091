import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
	BankAndReceiptTypeDataFormGroup,
	CustomerDataFormGroup
} from "../types/form";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import {
	EMPTY,
	Subject,
	catchError,
	startWith,
	switchMap,
	takeUntil,
	tap
} from "rxjs";
import { BankService } from "@data/services/bank.service";
import { IAtlasSelect } from "@shared/types/atlas";
import { AtlasToggleItemChangeEvent } from "../types/atlas-toggle-item-change-event";
import { WizardReceivableService } from "@data/services/wizard-receivable.service";
import { LinkedItemsTypeEnum } from "@data/schemas/wizard-receivable/enum/linked-items-type-enum";
import { AtlasSelectProperties } from "@shared/components/atlas/types/atlas-select-properties";
import { LinkedItems } from "@data/schemas/wizard-receivable/linked-items";
import { BanksNameEnum } from "@enums/banks-name.enum";
import { Steps } from "../types/steps.enum";

@Component({
	selector: "app-bank-and-receipt-type-data",
	templateUrl: "./bank-and-receipt-type-data.component.html",
	styleUrl: "./bank-and-receipt-type-data.component.scss",
	standalone: false
})
export class BankAndReceiptTypeDataComponent implements OnInit, OnDestroy {
	readonly BANK_NAME_ATTRIBUTE = "banco";
	readonly NOT_BASE_BANK = true;
	private tableUpdater$ = new Subject<HttpParamsConfig | undefined>();
	private _onDestroy = new Subject<boolean>();

	protected form!: FormGroup<BankAndReceiptTypeDataFormGroup>;
	protected linkedToggleSelected = true;
	protected blockWizardToLinkedItem = false;

	@ViewChild("atlasSelectReceiptBank")
	protected atlasSelectReceiptBank!: ElementRef<IAtlasSelect>;

	@ViewChild("atlasSelectLinkedItemType")
	protected atlasSelectLinkedItemType!: ElementRef<IAtlasSelect>;

	@ViewChild("atlasSelectLinkedItem")
	protected atlasSelectLinkedItem!: ElementRef<IAtlasSelect>;

	protected url = "";

	@Input()
	public formGroupName!: string;

	@Input() receiptForm: FormGroup | null = null;

	@Input() currentStep: string = "";

	@Input() interactionId: string = "";

	constructor(
		private rootFormGroup: FormGroupDirective,
		private bankService: BankService,
		private wizardReceivableService: WizardReceivableService
	) {}

	ngOnInit(): void {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<BankAndReceiptTypeDataFormGroup>;
		this.wizardOpenedByLinkedItem();
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this._onDestroy.complete();
	}

	protected receiptBank$ = this.tableUpdater$.pipe(
		startWith(undefined),
		switchMap(() =>
			this.bankService
				.getAllBanks(
					undefined,
					undefined,
					this.BANK_NAME_ATTRIBUTE,
					this.NOT_BASE_BANK
				)
				.pipe(
					tap((response) => {
						const asaasBank = response.find(
							(item) =>
								item.banco === BanksNameEnum.ASAAS.toString()
						);
						if (asaasBank) {
							this.form.controls.bank.patchValue(asaasBank.id);
						}
					}),
					catchError(() => EMPTY)
				)
		)
	);

	protected setSelectedBankName(): void {
		const selectedBank =
			this.atlasSelectReceiptBank.nativeElement?.getSelectedOptions();

		if (selectedBank?.label) {
			this.form.controls.bankName.setValue(selectedBank.label);
		}
	}

	protected linkedReceiptToggleChecked(event: Event): void {
		const atlasItemToggleChangeEvent = event as AtlasToggleItemChangeEvent;
		this.linkedToggleSelected = atlasItemToggleChangeEvent.detail.checked;

		if (this.linkedToggleSelected) {
			this.form.controls.linkedToggleSelected.patchValue(true);
			this.resetFieldsWhenChangeToggle();
			return;
		}

		this.form.controls.linkedToggleSelected.patchValue(false);
		this.resetFieldsWhenChangeToggle();
	}

	protected setLinkedItemType(): void {
		this.form.controls.linkedItem.patchValue(0);
		this.cleanCustomerData();

		if (!this.linkedToggleSelected) {
			return;
		}

		const selectedLinkedItemType =
			this.atlasSelectLinkedItemType.nativeElement
				.getSelectedOptions()
				.value.toString();

		if (selectedLinkedItemType === "invoice") {
			this.url = this.wizardReceivableService.getLinkedItemsByType(
				LinkedItemsTypeEnum.INVOICE_NFE
			);
		}

		if (selectedLinkedItemType === "invoiceRental") {
			this.url = this.wizardReceivableService.getLinkedItemsByType(
				LinkedItemsTypeEnum.INVOICE_RENTAL
			);
		}

		if (selectedLinkedItemType === "serviceInvoice") {
			this.url = this.wizardReceivableService.getLinkedItemsByType(
				LinkedItemsTypeEnum.INVOICE_NFSE
			);
		}

		if (selectedLinkedItemType === "serviceOrder") {
			this.url = this.wizardReceivableService.getLinkedItemsByType(
				LinkedItemsTypeEnum.SERVICE_ORDER
			);
		}

		if (selectedLinkedItemType === "salesOrder") {
			this.url = this.wizardReceivableService.getLinkedItemsByType(
				LinkedItemsTypeEnum.SALES_ORDER
			);
		}

		this.atlasSelectLinkedItem.nativeElement._selectOptions = [];
		this.atlasSelectLinkedItem.nativeElement._hasDoneFirstSearch = false;
	}

	protected getSelectedLinkedItem(): void {
		const selectedOption: AtlasSelectProperties =
			this.atlasSelectLinkedItem.nativeElement?.getSelectedOptions();
		const selectedLinkedItem =
			selectedOption.customProperties as LinkedItems;

		if (selectedLinkedItem) {
			this.form.controls.linkedItem.patchValue(selectedLinkedItem.id);
			this.form.controls.linkedItemValue.patchValue(
				selectedLinkedItem.value
			);
			this.form.controls.documentNumber.patchValue(
				selectedLinkedItem.documentNumber
			);
			this.receiptForm?.controls.receiptData
				.get("totalValue")
				?.patchValue(selectedLinkedItem.value);
			this.receiptForm?.controls.customerData
				.get("idCustomer")
				?.patchValue(selectedLinkedItem.idCompany);
		}
	}

	private resetFieldsWhenChangeToggle(): void {
		this.receiptForm?.controls.receiptData.get("totalValue")?.patchValue(0);
		this.form.controls.linkedItem.patchValue(0);
		this.form.controls.linkedType.patchValue("");

		this.form.controls.linkedItemValue.patchValue(0);
	}

	private wizardOpenedByLinkedItem(): void {
		this.wizardReceivableService.linkedItemData$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((linkedItem) => {
				if (linkedItem) {
					this.wizardReceivableService.trackWizardOpenedOn(
						linkedItem.type,
						this.interactionId
					);

					this.updateFormValuesByLinkedItem(
						linkedItem,
						this.wizardReceivableService.getLinkedItemTypeByEnum(
							linkedItem
						)
					);
				}
			});
	}

	private updateFormValuesByLinkedItem(
		linkedItem: LinkedItems,
		type: string
	): void {
		this.blockWizardToLinkedItem = true;
		this.form.controls.linkedItem.patchValue(linkedItem.id);
		this.form.controls.linkedItemValue.patchValue(linkedItem.value);
		this.form.controls.linkedType.patchValue(type);
		this.form.controls.documentNumber.patchValue(linkedItem.documentNumber);
		this.receiptForm?.controls.receiptData
			.get("totalValue")
			?.patchValue(linkedItem.value);
		this.receiptForm?.controls.customerData
			.get("idCustomer")
			?.patchValue(linkedItem.idCompany);
		this.form.controls.linkedType.disable();
	}

	private cleanCustomerData(): void {
		this.customerData.reset({
			idCustomer: 0,
			companyTaxpayerRegistration: "",
			legalName: "",
			email: "",
			phone: "",
			cellphone: "",
			postalCode: "",
			companyName: ""
		});

		this.cleanAddressData();
	}

	private cleanAddressData(): void {
		this.customerData.controls.city.patchValue("");
		this.customerData.controls.address.reset();
		this.customerData.controls.complement.reset();
		this.customerData.controls.neighborhood.reset();
		this.customerData.controls.addressNumber.reset();
		this.customerData.controls.uf.reset();
		this.customerData.controls.ibgeCode.reset();
	}

	get customerData(): FormGroup<CustomerDataFormGroup> {
		return this.receiptForm?.get(
			Steps.CUSTOMER_DATA
		) as FormGroup<CustomerDataFormGroup>;
	}
}
