<atlas-form id="receiptDataStepFormOtherBanks" [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			@if (isMigratedAccountingAccounts()) {
				<atlas-col lg="12">
					<atlas-fixed-alert
						[header]="
							'CREATE_RECEIPT.LABEL.NEW_CHART_ACCOUNT_HEADER'
								| translate
						"
					>
						<span
							[innerHTML]="
								'CREATE_RECEIPT.LABEL.NEW_CHART_ACCOUNT_LABEL'
									| translate
							"
						></span>
					</atlas-fixed-alert>
				</atlas-col>
			}
			<atlas-col lg="7">
				<atlas-section
					[header]="
						'CREATE_RECEIPT.RECEIPT_DATA_STEP.WHAT_TO_CHARGE'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="6">
								<atlas-money
									appAtlasFormElement
									formControlName="totalValue"
									min-value="0,01"
									data-testid="receipt-wizard-other-banks-total-value"
									[minValueErrorMessage]="
										'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.MESSAGE.MIN_VALUE'
											| translate
									"
									[required]="true"
									[attr.max-value]="
										this.bankTypeForm!.controls
											.linkedToggleSelected.value
											? maxValueWithLinkedItem()
											: 99999999999999
									"
									[maxValueErrorMessage]="
										maxValueErrorInLinkedOption()
									"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_VALUE'
											| translate
									"
								></atlas-money>
							</atlas-col>
						</atlas-row>
						<atlas-row>
							<atlas-col lg="11">
								<atlas-textarea
									appAtlasFormElement
									formControlName="observations"
									data-testid="receipt-wizard-other-banks-observations"
									maxlength="500"
									[label]="
										'CREATE_RECEIPT.RECEIPT_DATA_STEP.RECEIPT_DESCRIPTION'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.PLACEHOLDER.RECEIPT_DESCRIPTION'
											| translate
									"
								></atlas-textarea>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>

				<atlas-section
					[header]="
						'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.HOW_CUSTOMER_PAY'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="6">
								<atlas-layout gap="2">
									<atlas-select
										#atlasSelectPaymentMethod
										appAtlasFormElement
										formControlName="paymentMethod"
										data-testid="receipt-wizard-other-banks-payment-method"
										[required]="true"
										[label]="
											'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.PAYMENT_METHOD'
												| translate
										"
										[enableSearch]="true"
										[placeholder]="
											'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.PLACEHOLDER.PAYMENT_METHOD'
												| translate
										"
										(atlas-select-change)="
											getSelectedPaymentMethod()
										"
									>
										@if (paymentMethods()) {
											@for (
												paymentMethod of paymentMethods();
												track paymentMethod.id
											) {
												<atlas-option
													[label]="
														paymentMethod.descricao
													"
													[value]="paymentMethod.id"
												></atlas-option>
											}
										}
									</atlas-select>
								</atlas-layout>
							</atlas-col>
						</atlas-row>

						<atlas-row>
							<atlas-col lg="6">
								<atlas-select
									#atlasSelectPaymentMethodInstallment
									appAtlasFormElement
									formControlName="paymentMethodTerm"
									data-testid="receipt-wizard-other-banks-payment-method-term"
									[required]="true"
									[value]="
										defaultMethodInstallment()
											?.idInstallment
									"
									[defaultValue]="
										defaultMethodInstallment()
											?.idInstallment
									"
									[label]="
										'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.INSTALLMENT'
											| translate
									"
									[placeholder]="
										'CREATE_RECEIPT.OTHER_BANKS_RECEIPT_DATA_STEP.PLACEHOLDER.INSTALLMENT'
											| translate
									"
									[enableSearch]="true"
									(atlas-select-change)="
										getSelectedPaymentMethodInstallment()
									"
								>
									@if (paymentMethodsInstallments()) {
										@for (
											paymentMethodInstallment of paymentMethodsInstallments();
											track paymentMethodInstallment.idInstallment +
												paymentMethodInstallment.valueFromAsaas
										) {
											<atlas-option
												[label]="
													setLabelToPaymentMethodTerms(
														paymentMethodInstallment
													)
												"
												[value]="
													paymentMethodInstallment.idInstallment
												"
											></atlas-option>
										}
									}
								</atlas-select>
							</atlas-col>
							<atlas-col lg="5">
								<atlas-layout gap="2">
									<atlas-date-picker
										appAtlasFormElement
										formControlName="dueDate"
										data-testid="receipt-wizard-other-banks-due-date"
										[required]="true"
										[label]="receiptDueDateLabel"
									></atlas-date-picker>
								</atlas-layout>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
			</atlas-col>
		</atlas-row>
		<app-management-organization-details
			[currentStep]="currentStep"
		></app-management-organization-details>
	</atlas-grid>
</atlas-form>
